<template>
  <div class="under-construction">
    <div class="container mx-auto max-w-sm flex flex-col items-center">
      <h1 class="mt-8 font-semibold mb-2">BAKIM</h1>
      <p class="mt-2 mb-8">Daha iyi bir deneyim için çalışma yapıyoruz. En kısa sürede oyun oynamaya başlayabilirsin</p>
      <img class="w-64" src="@/assets/under-construction.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>