<template>
  <div>
    <modal
        name="maintenance-modal"
        classes="modal"
        :minWidth="200"
        :minHeight="400"
        :maxWidth="480"
        :scrollable="true"
        :adaptive="true"
        :reset="true"
        width="100%"
        :clickToClose="false"
        height="auto">
      <div class="flex flex-col m-5">
        <h1 class="text-center">Bakım Yapıyoruz</h1>
        <h2 class="text-center">Daha iyi bir deneyim için çalışma yapıyoruz. En kısa sürede oyun oynamaya başlayabilrsin.</h2>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  methods: {
    show() {
      this.$modal.show("maintenance-modal");
    },
    hide() {
      this.$modal.hide("maintenance-modal");
    }
  }
}
</script>