<template>
  <div class="home">
    <result-modal ref="resultModal"></result-modal>
    <div class="flex flex-col items-center justify-center">
      <div class="home-background w-full mb-8">
        <img src="@/assets/background.svg" alt="">
      </div>
      <div class="flex flex-col ">
        <button class="btn btn--secondary mb-2 self-center heartbeat" @click.prevent="onClickOynaKazan" v-if="showOynakazan">OYNA KAZAN</button>
        <div class="text-center text-sm mb-6 px-6" v-if="showOynakazan">Oyna Kazan ile kelimeyi bulursan anında <b>1.000</b>, bulamazsan <b>500</b> Paycell puan hediye!</div>
        <button class="btn btn--primary btn--sm self-center mb-4" @click="onClickLeagueGameButton">ÖDÜLLÜ LİG</button>
        <h1 class="text-center home-promo mb-8">Günlük Ligde <b class="home-promo__underline-orange">İlk 10’a</b> Gir <span
            class="home-promo__circle-gray">Binlerce</span> <br> <b class="home-promo__underline-gray">PAYCELL PUAN</b>
          Kazan!!</h1>
        <navigation-bottom class="mb-4"></navigation-bottom>
      </div>
      <league-game-modal ref="leagueGameModal"></league-game-modal>
      <oyna-kazan-modal ref="oynaKazanModal"></oyna-kazan-modal>
    </div>
    <no-ticket-modal ref="noTicketModal" v-if="this.$store.state.authenticated"></no-ticket-modal>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import ResultModal from '@/views/components/ResultModal'
import LeagueGameModal from '@/views/components/LeagueGameModal'
import NavigationBottom from '@/views/components/NavigationBottom.vue'
import NoTicketModal from "@/views/components/NoTicketModal";
import OynaKazanModal from "@/views/components/OynaKazanModal";

export default {
  components: {
    LeagueGameModal, NavigationBottom, NoTicketModal, ResultModal, OynaKazanModal
  },
  data() {
    return {
      showOynakazan: false,
      oynaKazan: null,
      pendingOynaKazan: 0
    }
  },
  created() {
    setTimeout(() => {
      this.calculateOynaKazan()
      this.retrieveProductGroups()
      if(this.pendingOynaKazan != null && this.pendingOynaKazan.length > 0){
        setTimeout(() => {
          this.$store.dispatch('paycellLogin').then(()=>{
            this.calculateOynaKazan()
            var child = this.$refs.oynaKazanModal;
            child.reCalculate();
          })
        }, 60000);
      }
    },300)

//    let testers = this.$route.query.externalId
//    if(this.$route.query.version && (testers == 'bodev' || this.player.isTestUser == 'y')) { this.showOynakazan = true }
    if(this.$route.query.version) { this.showOynakazan = true }
  },
  computed: {
    ...mapState(["player"]),
  },
  methods: {
    ...mapActions(["createGame", "getPlayerInfo", "retrieveProductGroups", "purchaseStart"]),
    ...mapGetters(["checkTicket"]),
    onClickOynaKazan() {
      this.calculateOynaKazan()
      this.$modal.show("oyna-kazan-modal");
    },
    calculateOynaKazan(){
      this.oynaKazan = JSON.parse(sessionStorage.getItem("oynaKazanUnplayedGames"))
      this.pendingOynaKazan = JSON.parse(sessionStorage.getItem("oynaKazanWaithingCreate"))
    },
    onClickLeagueGameButton() {
      if (this.checkTicket()) {
        this.$refs.leagueGameModal.show()
      } else {
        this.$modal.show('no-ticket-modal');
      }
    },
    closeModalNoTicket()
    {
      this.$modal.hide('no-ticket-modal')
    },
    oynaKazanError(){
      this.$refs.resultModal.message = 'Oyna Kazan ödemeniz onaylanmamıştır';
      this.$refs.resultModal.success = false
      this.$refs.resultModal.show()
    },
    gotoOynaKazan(detail){
      localStorage.setItem("atGame", 'true')
      this.$router.push({name: "game", params: {id: detail.id}, query: this.$route.query})
    }
  }
}
</script>

<style>

</style>