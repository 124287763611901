<template>
  <div class="flex justify-evenly">
    <div class="flex flex-col items-center">
      <router-link class="btn-circle mx-2" :to="{name: 'leaderboard', query: this.$route.query}">
        <img :src="require('@/assets/icon-league.svg')" alt="">
      </router-link>
      <span class="font-semibold text-xs mt-3 text-center">SKOR TABLOSU</span>
    </div>
    <div class="flex flex-col items-center">
      <!-- <button class="btn-circle mx-2" @click="onClickSettingsButton"><img :src="require('@/assets/icon-settings.svg')" alt=""></button> -->
      <router-link class="btn-circle mx-2" :to="{name: 'settings', query: this.$route.query}"><img :src="require('@/assets/icon-settings.svg')" alt=""></router-link>
      <span class="font-semibold text-xs mt-3 text-center">AYARLAR</span>
    </div>
    <div class="flex flex-col items-center">
      <router-link class="btn-circle mx-2" :to="{name: 'store', query: this.$route.query}"><img :src="require('@/assets/icon-store.svg')" alt=""></router-link>
      <span class="font-semibold text-xs mt-3 text-center">MAĞAZA</span>
    </div>
    <settings-modal ref="settingsModal"></settings-modal>
  </div>
</template>

<script>
import SettingsModal from "@/views/components/SettingsModal.vue";
export default {
  components: {
    SettingsModal
  },
  methods: {
    onClickSettingsButton() {
      this.$refs.settingsModal.show();
    }
  }
}
</script>

<style>

</style>