<template>
  <div class="store">
    <div class="container mx-auto max-w-sm">
      <store-content :order="order"></store-content>
    </div>
  </div>
</template>

<script>
import StoreContent from "@/views/components/StoreContent"
export default {
  components: {
    StoreContent
  },
  props: {
    order: String
  },
}
</script>

<style>

</style>