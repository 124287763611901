<template>
  <div class="game vld-parent h-screen">
    <loading :active.sync="this.$store.state.isLoading" :is-full-page="true" :lock-scroll="true"
      :backgroundColor="`#000000`" :color="`#FFB74F`" :blur="`7px`" :opacity="0.5" :width="64" :height="64"></loading>
    <result-modal ref="resultModal"></result-modal>
    <v-dialog :width="600" />
    <div class="game-wrapper flex flex-col h-full">
      <div class="game-header container max-w-sm px-4 py-2 mx-auto">
        <div class="flex mb-2">
          <div class="flex flex-col xs:flex-row align-center">
            <router-link class="mr-2" :to="{name: 'dashboard', query: this.$route.query}"><img
                src="@/assets/icon-back.svg" alt=""></router-link>
            <div class="flex align-center">
              <div class="oynakazanHide game-meter mr-2">
                <div class="game-meter__icon">
                  <img src="@/assets/icon-score.svg" alt="">
                </div>
                <div class="game-meter__value">
                  {{ score }}
                </div>
              </div>
              <div class="game-meter">
                <div class="game-meter__icon">
                  <img src="@/assets/icon-live.svg" alt="">
                </div>
                <div class="game-meter__value">
                  {{ lives }}
                </div>
              </div>
              <div class="game-meter" style="margin-left: 0;" v-if="this.gameType == 0">
                <img width="60%" style="margin: 0 auto" src="/img/icon-oyna-kazan.4e67ca25.svg" />
              </div>
            </div>

          </div>
          <currencies class="ml-auto"></currencies>
        </div>
        <!-- <h3 class="game-header__lives mt-3"><img class="h-100" src="@/assets/lives.svg" alt="CAN"> : {{lives}}</h3> -->
      </div>
      <div class="game-container flex flex-col align-center">
        <div id="hangman" ref="hangman" class="w-full game-image relative">
          <div class="game-board w-full h-full">
            <div class="game-board__svg h-full flex-grow">
              <svg id="gameSVG" ref="gameSVG" width="100%" height="100%" viewBox="0 0 930 287" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 273C41.2486 273.416 79.1641 279.399 117.329 275.173C145.577 272.045 170.866 277.588 198.754 279.043C231.98 280.777 264.905 275.242 298.089 276.735C333.048 278.307 365.994 279.163 400.963 276.938C427.391 275.257 454.519 274.533 480.931 276.395C503.478 277.985 527.968 279.111 550.694 279.111C594.952 279.111 640.053 273.76 684.286 277.278C708.568 279.209 733.968 277.889 758.423 277.889C783.759 277.889 809.019 280.094 834.225 281.556C865.046 283.342 896.322 281.499 927 284"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M269.069 270.487C270.203 272.855 271.13 276.56 272.931 278.608C274.094 279.931 273.981 279.695 274.793 278.114C276.537 274.72 277.557 270.057 280.241 267.309"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M223.414 278.75C223.414 274.349 224.095 267.433 222.172 263.496" stroke="black"
                  stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M588.586 270.487C589.313 271.499 592.313 277.944 593.241 275.854C594.076 273.973 594.502 271.975 595.448 270.099C597.214 266.594 599.936 263.768 601.621 260.318"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M592.31 275.572C591.634 272.524 590.257 269.75 589.827 266.674" stroke="black" stroke-width="5"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M594.793 273.665C594.142 270.407 593.089 267.319 592.31 264.131" stroke="black"
                  stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M298.517 278.75C300.082 273.842 301 267.998 301 262.825C301 259.531 302.17 256.183 302.241 252.832C302.35 247.692 302.621 242.913 302.276 237.79C301.639 228.333 299.624 218.928 299.138 209.471C298.929 205.409 297.896 201.488 297.896 197.396C297.896 192.311 297.896 187.226 297.896 182.142C297.896 172.58 298.517 163.148 298.517 153.576C298.517 148.497 299.086 143.489 299.138 138.428C299.19 133.265 298.517 128.175 298.517 123.068C298.517 113.683 297.127 104.345 297.276 94.9262C297.364 89.3271 299.138 84.0055 299.138 78.5424"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M299.641 114.874C305.977 106.14 311.433 95.6742 319.848 88.7804C323.531 85.7632 327.43 82.6785 330.055 78.6465"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M276.172 76C287.31 76 298.121 78.4563 309.207 78.5423C315.879 78.5941 322.465 78.0506 329.069 77.9067C338.777 77.6952 347.767 77.2641 357.483 78.4011C366.009 79.3989 374.674 77.9067 383.207 77.9067C395.567 77.9067 407.837 77.2712 420.172 77.2712C424.899 77.2712 429.596 78.5423 434.31 78.5423C438.333 78.5423 442.356 78.5423 446.379 78.5423C451.506 78.5423 456.632 78.5423 461.759 78.5423C470.515 78.5423 479.187 77.9067 487.828 77.9067"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 7"
                  d="M441.276 79.8135C443.517 87.127 442.517 95.5428 442.517 103.189C442.517 107.455 443.138 111.656 443.138 116.042"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 6"
                  d="M454.31 121.762C449.783 119.952 445.77 117.6 441 116.712C436.78 115.927 430.626 118.107 427.931 121.479C424.792 125.408 423.276 130.901 423.276 135.886C423.276 140.257 423.196 146.922 426.379 150.363C434.886 159.557 451.661 163.674 459.586 151.952C462.86 147.11 462.537 140.135 462.379 134.473C462.317 132.229 461.453 128.826 460.379 126.846C458.683 123.719 455.003 123.088 452.448 121.126"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 5"
                  d="M441.276 162.439C447.571 169.075 453.856 176.27 459.586 183.413C461.532 185.839 464.666 191.675 467.966 191.675"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 4"
                  d="M439.414 160.532C435.715 164.412 431.87 168.05 428.241 171.972C426.746 173.589 424.156 175.348 423.276 177.375C422.482 179.203 414.368 188.068 415.207 188.497"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 3"
                  d="M440.034 160.532C440.034 176.502 441.896 192.373 441.896 208.236C441.896 215.642 443.138 222.887 443.138 230.446"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 2"
                  d="M443.138 230.446C441.513 233.069 439.797 236.007 437.828 238.39C435.479 241.233 433.379 244.764 430.724 247.288C428.136 249.749 424.2 253.34 422.655 256.504"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 1"
                  d="M444.38 232.988C448.165 238.105 451.846 243.476 455.069 248.877C456.071 250.556 457.661 251.827 458.655 253.609C459.242 254.661 461.759 257.37 461.759 255.869"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M576 276C578.605 271.458 581.182 267.337 582.467 262.302C583.687 257.518 585.126 252.766 587.1 248.258C591.392 238.459 591.459 224.905 591.6 214.358C591.841 196.367 594 178.482 594 160.535C594 155.023 594 149.512 594 144"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M619.98 134.586C619.98 150.84 619.562 166.524 621.851 182.543C622.939 190.159 624.02 193.334 624.02 200.573C624.02 206.97 624.02 213.367 624.02 219.764C624.02 231.436 628.604 241.689 632.437 252.421C634.176 257.289 636.14 263.781 636.14 268.918C636.14 270.721 636.632 277.917 638.16 278.682"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M588.594 167.707C579.905 167.707 569.677 169.357 561.398 166.428C554.136 163.859 545.632 161.315 539.551 156.474C532.361 150.751 531.036 143.099 531.736 134.39C532.011 130.969 534.521 126.809 535.8 123.537C536.995 120.479 539.487 116.605 542.052 114.517C543.09 113.672 551.013 106.689 550.284 106.084C548.221 104.373 545.819 103.37 543.893 101.453C541.743 99.3142 539.779 96.9318 537.919 94.5406C533.699 89.1178 526.364 79.5448 529.965 72.1795C532.74 66.5033 537.175 60.2372 543.267 57.9057C543.771 57.713 545.946 57.6586 545.351 57.56C542.851 57.1454 541.081 54.9732 539.864 52.9288C535.259 45.1971 540.399 37.3443 546.741 32.987C553.037 28.6612 557.771 28.3558 565.115 28.3558C566.445 28.3558 568.654 28.7718 569.908 28.2175C571.66 27.4425 573.079 25.2159 574.354 23.8974C577.223 20.9279 580.096 17.9485 583.002 15.0151C587.953 10.0179 600.713 -2.25547 608.322 5.51078C611.284 8.53281 613.68 12.1025 615.964 15.6372C616.235 16.0575 618.688 19.9919 619.229 19.9919C622.676 19.9919 627.932 16.5373 631.246 15.4299C636.422 13.7003 641.72 12.1252 647.223 12.1811C656.908 12.2794 662.918 21.6863 665.354 30.0838C666.419 33.7548 666.743 37.342 666.743 41.1434C666.743 43.4235 668.08 41.505 669.522 40.7978C678.043 36.6199 688.9 37.5209 692.863 47.0189C696.594 55.9634 695.735 69.0788 690.015 76.8798C689.416 77.6955 687.315 79.0488 688.278 79.3682C690.75 80.188 692.956 79.8738 695.363 81.511C706.033 88.7678 705.834 107.54 700.087 117.593C698.348 120.635 696.145 122.539 693.627 124.782C693.101 125.249 691.751 125.272 691.751 126.026C691.751 135.248 694.317 143.302 689.876 152.223C685.688 160.636 677.555 159.619 669.522 159.619C667.044 159.619 664.327 159.315 661.881 159.758C657.465 160.557 654.38 154.391 651.461 151.532C648.02 148.161 649.194 151.732 649.863 154.159C650.662 157.058 651.114 159.728 651.114 162.73C651.114 167.367 651.7 171.683 647.988 175.172C642.507 180.322 634.646 173.2 629.857 170.817"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M545.401 69.9771C545.401 78.3614 545.374 85.4239 553.006 90.5121" stroke="black"
                  stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M586.471 31.9494C589.662 26.8441 593.841 23.5833 599.781 23.5833C604.302 23.5833 607.766 28.4447 607.766 32.71"
                  stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M550.725 134.624C550.725 142.218 554.654 145.173 560.992 148.694C563.871 150.294 567.165 150.804 570.119 152.117C571.737 152.836 576.688 153.087 577.344 154.399"
                  stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
        <div class="game-canvas">
          <div class="container mx-auto max-w-sm relative">
            <!--div class="ml-1 flex flex-col left-0 absolute -top-8 items-center">
              <button class="btn btn--xs btn--primary self-center" @click="onClickPowerup(powerup.relive)"><img
                  src="@/assets/icon-relive.svg" alt=""></button>
              <div class="flex items-center font-semibold text-lg">
                <span>{{ rewindCount > 0 ? `x ${rewindCount}` : powerup.relive.value }}</span> <img class="ml-1 w-4"
                  src="@/assets/icon-coin.svg" alt="" v-if="rewindCount < 1">
              </div>
            </div-->
            <div class="hangman-word__category flex justify-center self-start">
              <h4 class="m-0" :style="[payload && payload.category.length > 20 ? {'font-size': '11px'} : '']">{{ payload ? payload.category : "" }}</h4>
            </div>
            <!--div class="mr-1 flex right-0 absolute -top-8 flex-col items-center">
              <button class="btn btn--xs btn--primary self-center" @click="onClickPowerup(powerup.clue)"><img
                  src="@/assets/icon-clue.svg" alt=""></button>
              <div class="flex items-center font-semibold text-lg">
                <span>{{ clueCount > 0 ? `x ${clueCount}` : powerup.clue.value }}</span> <img class="ml-1 w-4"
                  src="@/assets/icon-coin.svg" alt="" v-if="clueCount < 1">
              </div>
            </div-->
          </div>
          <div class="overflow-hidden relative flex flex-col items-center" style="padding-bottom: 60px">
            <div class="hangman-word flex justify-center items-center" v-if="word">
              <template v-if="wordSpaceCount == 0 || (wordArray.length <12 && wordSpaceCount > 0)">
                <div class="mx-2" v-for="word, i in guess" :key="`word-${i}`">
                  <span class="hangman-word__char mt-3"
                    :class="{'is-space': character === ' ', 'small': wordArray.length > 12}"
                    v-for="(character,index) in word" :key="`char-${index}`">
                    {{ character }}
                  </span>
                </div>
              </template>
              <template v-if="wordArray.length >= 12 && wordSpaceCount > 0">
                <div class="flex flex-col items-center">
                  <div v-for="word, i in guess" :key="`word-${i}`">
                    <span class="hangman-word__char mt-3"
                      :class="{'is-space': character === ' ', 'small': wordSpaceCount > 2}"
                      v-for="(character,index) in word" :key="`char-${index}`">
                      {{ character }}
                    </span>
                  </div>
                </div>
              </template>
            </div>
            <div class="flex justify-center alphabet mt-5">
              <div class="grid grid-cols-6 justify-center">
                <button role="button" class="alphabet__char mx-1"
                  :class="[{'is-right': _.indexOf(rightCharacters, character) >= 0}, {'is-wrong': _.indexOf(wrongCharacters, character) >= 0}]"
                  :disabled="_.indexOf(rightCharacters, character) >= 0 || _.indexOf(wrongCharacters, character) >= 0"
                  v-for="(character,index) in alphabet" :key="`alphabet-char-${index}`"
                  @click="onClickCharacter(character)">
                  {{ character }}
                  <span class="alphabet__char__wrong-img"><img src="@/assets/times.svg" alt=""></span>
                  <span class="alphabet__char__right-img"><img src="@/assets/circle.svg" alt=""></span>
                </button>
              </div>
            </div>
            <div ref="hintsPanel" class="hints-panel" :class="{'hints-panel--active': showHints}">
              <div class="absolute right-0 mr-4 mt-4 z-50 cursor-pointer" style="height: 24px" v-if="showHints"
                @click="showHints = false">
                <img class="max-h-full" src="@/assets/times-black.svg" alt="">
              </div>
              <div class="hints-panel__tabs">
                <div class="hints-panel__tabs-item cursor-pointer" :class="{active: index == activeHint && showHints}"
                  :style="{'right': (index * 50) + 8 + 'px'}" @click="onClickHintTabItem(index)"
                  v-for="(item, index) in usedHints" :key="`hints-tab-${index}`">
                  <img src="@/assets/icon-clue-sml.svg" class="mr-1" alt="">
                  {{ index + 1 }}
                </div>
              </div>
              <div class="hints-panel__content">
                {{ hints[activeHint] }}
              </div>
            </div>
          </div>
          <div class="powerups">
            <div class="btn-game-store cursor-pointer z-50" @click="$modal.show('store-modal')">
              <img src="@/assets/icon-store.svg" alt="">
              MAĞAZA
            </div>
            <div class="power-button-group relative">
              <div class="powerup-button" ref="relivePowerup" @click="onClickPowerup(powerup.relive)">
                <h6>GERİ AL</h6>
                <button class="btn btn--xs joker-button">
                  <img src="@/assets/relive-button.svg" alt="">
                </button>
                <div class="powerup-button__price">
                  <span>{{ rewindCount > 0 ? `x ${rewindCount}` : powerup.relive.value }}</span>
                  <img src="@/assets/coin-small.svg" v-if="rewindCount < 1">
                </div>
              </div>
              <div class="powerup-button" ref="openPowerup" @click="onClickPowerup(powerup.clue)">
                <h6>HARF AÇ</h6>
                <button class="btn btn--xs joker-button">
                  <img src="@/assets/block-button.svg" alt="">
                </button>
                <div class="powerup-button__price">
                  <span>{{ clueCount > 0 ? `x ${clueCount}` : powerup.clue.value }}</span>
                  <img src="@/assets/coin-small.svg" v-if="clueCount < 1">
                </div>
              </div>
              <!--
              <div class="powerup-button" @click="onClickPowerup(powerup.change)">
                <h6>PAS</h6>
                <button class="btn btn--xs joker-button">
                  <img src="@/assets/skip-button.svg" alt="">
                </button>
                <div class="powerup-button__price">
                  <span>{{ changeCount > 0 ? `x ${changeCount}` : powerup.change.value }}</span>
                  <img src="@/assets/coin-small.svg" v-if="changeCount < 1">
                </div>
              </div>
-->
              <div class="powerup-button" ref="hintPowerup"
                :class="{'disabled': hints.length == 0 || usedHints.length == hints.length}"
                @click="onClickPowerup(powerup.hint)">
                <h6>İPUCU</h6>
                <button class="btn btn--xs joker-button">
                  <img src="@/assets/clue-button.svg" alt="">
                </button>
                <div class="powerup-button__price">
                  <span>{{ hintCount > 0 ? `x ${hintCount}` : powerup.hint.value }}</span>
                  <img src="@/assets/coin-small.svg" v-if="hintCount < 1">
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
      <modal name="game-result-modal" 
        classes="modal" 
        :minWidth="200" 
        :minHeight="400" 
        :maxWidth="480"
        :scrollable="true" 
        :adaptive="true" 
        :clickToClose="false" 
        :reset="true" 
        width="90%" 
        height="auto">
        <div class="flex flex-col items-center h-full">
          <template v-if="gameStatus == 'w'">
            <h1 class="my-4 font-semibold flex flex-col items-center">TEBRİKLER <span
                class="font-bold text-yellow-500">+5 KOİN</span><span v-if="gameType == '0'"
                class="font-bold text-yellow-500">1000 PAYCELL PUAN!</span></h1>
            <div v-if="gameType == '0'">En kısa süre içerisinde paycell puanlarınız tanımlanacaktır.</div>
            <img class="mb-4" src="@/assets/icon-win.svg" alt="">
          </template>
          <template v-else-if="gameStatus == 'l'">
            <h1 class="mt-4 font-semibold">ÜZGÜNÜZ !</h1>
            <h3 class="mt-2 mb-2 font-bold text-yellow-500" v-if="gameType == '1'">KOİN KAZANAMADIN</h3>
            <h1 class="mt-2 mb-2 font-bold text-yellow-500" v-if="gameType == '0'">500 PAYCELL PUAN</h1>
            <img class="mb-4" src="@/assets/icon-sad.svg" alt="">
          </template>
          <div class="modal-container">
            <div class="hangman-word flex justify-center items-center mb-4" v-if="word">
              <template v-if="wordSpaceCount == 0 || (wordArray.length < 12 && wordSpaceCount > 0)">
                <div class=" mx-2" v-for="word, i in wordSplit" :key="`word-${i}`">
                  <span class="hangman-word__char text-success mt-3"
                    :class="{'is-space': character === ' ', 'text-success': gameStatus == 'w', 'text-danger': gameStatus == 'l', 'small': wordArray.length > 12}"
                    v-for="(character,index) in word" :key="`char-${index}`">
                    {{ character }}
                  </span>
                </div>
              </template>
              <template v-if="wordArray.length >= 12 && wordSpaceCount > 0">
                <div class="flex flex-col items-center">
                  <div v-for="word, i in wordSplit" :key="`word-${i}`">
                    <span class="hangman-word__char text-success mt-3"
                      :class="{'is-space': character === ' ', 'text-success': gameStatus == 'w', 'text-danger': gameStatus == 'l', 'small': wordSpaceCount > 2}"
                      v-for="(character,index) in word" :key="`char-${index}`">
                      {{ character }}
                    </span>
                  </div>
                </div>
              </template>
            </div>
            <table class="mb-4" v-if="gameType == '1'" style="max-width: 140px">
              <tr>
                <td class="text-right leading-none p-0 pr-4">
                  SKOR :
                </td>
                <td class="font-semibold p-0 text-lg text-center">
                  {{ score }}
                </td>
              </tr>
              <tr>
                <td class="text-right leading-none p-0 pr-4">
                  <img class="inline mr-2" src="@/assets/plus.png" alt=""> BONUS :
                </td>
                <td class="font-semibold p-0 text-lg text-center">
                  {{ bonus }}
                </td>
              </tr>
              <tr>
                <td colspan="2"><img src="@/assets/underline-gray.svg" alt=""></td>
              </tr>
              <tr>
                <td class="flex justify-end">
                  <div class="icon-circle mr-6 text-center">
                    <img src="@/assets/icon-score.svg" alt="">
                  </div>
                </td>
                <td class="text-center">
                  <span class="text-4xl font-semibold"> {{ totalScore }}</span>
                </td>
              </tr>
            </table>
            <div class="leaderboard leaderboard--single mx-auto px-4 mb-4" v-if="gameType == '1'">
              <div class="leaderboard__item" :class="{'active': standing.player.id == player.id}"
                v-for="(standing,i) in standingArray" :key="i">
                <div class="leaderboard__item-number">
                  {{ standing.currentRank ? standing.currentRank : '-' }}.
                </div>
                <div class="leaderboard__item-info">
                  <div class="leaderboard__item-avatar">
                    <img :src="standing.player.avatarUrl" alt="">
                  </div>
                  <div class="leaderboard__item-name">{{ standing.player.alias }}</div>
                </div>
                <div class="leaderboard__item-points">{{
                    standing.score ? standing.score : '-'
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center mb-4" v-if="gameType == 1">
            <router-link :to="{name: 'dashboard', query: this.$route.query}" class="btn btn--secondary btn--sm">ANA
              MENÜ
            </router-link>
            <button class="btn btn--primary -ml-2" @click="onClickPlayAgain">TEKRAR <br> OYNA</button>
          </div>
          <div class="flex items-center justify-center mb-4 w-full px-4" v-else-if="gameType == 0">
            <router-link :to="{name: 'dashboard', query: this.$route.query}" class="btn btn--secondary btn--sm">ANA
              MENÜ
            </router-link>
            <div class="btn-oyna-kazan btn-oyna-kazan--primary ml-2" v-if="this.oynaKazan.length == 0" @click="onClickPurchaseAgain">
              OYNA KAZAN
              <div class="text-stroke mb-4 font-normal"> x1 </div>
              <div class="btn-oyna-kazan__value">
                <span class="btn-oyna-kazan__value-number">8</span>
                <span class="btn-oyna-kazan__value-currency">
                  TL
                </span>
              </div>
            </div>
            <div class="btn-oyna-kazan btn-oyna-kazan--primary ml-2" @click="onClickPlayAgain" v-if="this.oynaKazan.length > 0">
              <h2>KALAN HAKKIN : {{this.oynaKazan.length}}</h2>
              <div class="text-lg"> TEKRAR <br /> OYNA</div>
            </div>
          </div>
          <div class="text-center text-sm mb-6 px-6" v-if="gameType == 0">Oyna Kazan ile kelimeyi bulursan anında <b>1.000</b>, bulamazsan <b>500</b> Paycell puan hediye!</div>
        </div>
      </modal>
      <modal name="no-ticket-modal" classes="modal" :minWidth="200" :minHeight="400" :maxWidth="480" :scrollable="true"
        :clickToClose="false" :reset="true" width="90%" height="auto">
        <div class="flex flex-col items-center">
          <h1 class="my-4 font-semibold">ÜZGÜNÜZ !</h1>
          <img class="mb-4" src="@/assets/icon-sad.svg" alt="">
          <h3>Oynamak için yeterli biletiniz yok.</h3>
          <div class="flex justify-center items-center mb-8">
            <div class="icon-circle mr-4">
              <img src="@/assets/icon-ticket.svg" alt="">
            </div>
            <span class="text-2xl font-semibold">BİLET : {{ player.powerups.ticket }}</span>
          </div>
          <div class="flex items-center mb-4">
            <router-link :to="{name: 'dashboard', query: this.$route.query}" class="btn btn--secondary btn--sm">ANA
              MENÜ
            </router-link>
            <router-link :to="{name: 'store-order', query: this.$route.query, params: {order: 'ticket'}}"
              class="btn btn--primary -ml-2">MAĞAZAYA GİT
            </router-link>
          </div>
        </div>
      </modal>
      <modal 
        name="oynakazan-info-modal" 
        classes="modal" 
        :minWidth="200" 
        :minHeight="400" 
        :maxWidth="480" 
        :scrollable="true"
        :adaptive="true"
        :clickToClose="false" 
        :reset="true"
         width="90%" 
         height="auto">
        <div class="flex flex-col items-center px-8 mt-8">
          <img src="@/assets/icon-oyna-kazan.svg" alt="">
          <h3 class="my-4 text-center">{{oynaKazanMessage}}</h3>
          <h3>Keyifli Oyunlar!</h3>
          <div class="flex items-center mb-4" style="padding-top: 20px">
            <button :class="['btn self-center mb-5', 'btn--primary']"
                    style="padding: 8px 25px;" @click="$modal.hide('oynakazan-info-modal')">Tamam
            </button>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapState
  } from "vuex";
  import Currencies from "@/views/components/Currencies"
  import ResultModal from '@/views/components/ResultModal'

  export default {
    components: {
      Currencies, ResultModal
    },
    props: {
      category: {
        type: String,
        default: ""
      },
      gameId: [Number, String]
    },
    computed: {
      ...mapState(["player", "gamePlay"]),
      word() {
        return this.payload ? this.payload.word : null;
      },
      wordArray() {
        return this.word ? this.word.split("") : [];
      },
      largeWordArray() {
        var arr = this._.cloneDeep(this.wordArray)
        this._.forEach(this.wordArray, (character) => {
          if (this.flattenSpecialChars.includes(character)) {
            let equalKey = this._.findKey(this.specialChars, (chrArray) => {
              return chrArray.includes(character)
            })

            if (equalKey) {
              arr.push(equalKey)
            }
          }
        })
        return arr
      },
      rewindCount() {
        return this.player.powerups.rewind
      },
      clueCount() {
        return this.player.powerups.open
      },
      changeCount() {
        return this.player.powerups.change
      },
      hintCount() {
        return this.player.powerups.hint
      },
      totalScore() {
        return this.score + this.bonus
      },
      bonus() {
        return this.lives
      },
      flattenSpecialChars() {
        return this._.flattenDeep(this._.values(this.specialChars))
      }
    },
    watch: {
      wordArray() {
        let alphabet = this._.concat(this.alphabet, this.flattenSpecialChars)

        this.guess = this._.map(this.wordArray, (character) => {
          if (this._.indexOf(alphabet, character) < 0) {
            return character;
          }

          return character != " " ? "" : character;
        });

        let count = this._.filter(this.wordArray, function (o) {
          if (o == ' ') return o
        });

        this.wordSpaceCount = count.length;

        let words = []
        let copyArray = this._.cloneDeep(this.wordArray);
        let i = 0;
        while (copyArray.length > 0) {
          if (copyArray[i] == ' ') {
            words.push(copyArray.slice(0, i));
            copyArray.splice(0, i + 1);
            i = -1;
          } else if (i == copyArray.length - 1) {
            words.push(copyArray.slice(0, i + 1));
            copyArray.splice(0, i + 1);
            i = 0;
          }
          i++;
        }
        this.wordSplit = [...words]

        this.guess = this._.map(this.wordSplit, (word) => {
          return this._.map(word, (character) => {
            if (this._.indexOf(alphabet, character) < 0) {
              return character;
            }

            if (this.flattenSpecialChars.includes(character)) {
              let equalChar = this._.findKey(this.specialChars, function (arr) {
                return arr.includes(character)
              })

              if (this.rightCharacters.includes(equalChar)) {
                return character
              }
            }

            return this.rightCharacters.includes(character) ? character : (character != " " ? "" : character);
          })
        })
      }
    },
    closeHintsPanel(e) {
      if (!e.target.closest(".hints-panel")) {
        this.showHints = false;
        document.removeEventListener("mouseDown", this.closeHintsPanel);
      }
    },
    showHints() {
      if (this.showHints) {
        document.addEventListener("mousedown", this.closeHintsPanel);
      }
    },
    data() {
      return {
        lockPage: true,
        payload: null,
        moveCount: 0,
        moves: [],
        usedPowerupsLetters: [],
        usedPowerups: [],
        hints: [],
        usedHints: [],
        activeHint: 0,
        showHints: false,
        lives: 7,
        score: 0,
        winPoint: 0,
        rightAnswerPoint: 1,
        guess: null,
        gameStatus: null,
        oynaKazan: null,
        gameBoardHeight: 0,
        rightCharacters: [],
        wrongCharacters: [],
        wordSpaceCount: 0,
        openedIChar: false,
        wordSplit: [],
        windowHeight: 0,
        standingArray: [],
        gameType: 1,
        leaveMessage: `Oyundan ayrılmak oyunun kapanmasına neden olacak. Oyundan ayrılmak istediğinize emin misiniz ?`,
        oynaKazanMessage: `Oyna Kazan'dasın, oyundan ayrılırsan oyun kapanır ve sadece teselli ödülü alabilirsin.`,
        powerup: {
          clue: {
            id: "c",
            value: 50
          },
          relive: {
            id: "r",
            value: 30
          },
          change: {
            id: "p",
            value: 60
          },
          hint: {
            id: "h",
            value: 40
          }
        },
        alphabet: ["A", "B", "C", "Ç", "D", "E", "F", "G", "Ğ", "H", "I", "İ", "J", "K", "L", "M", "N", "O", "Ö", "P",
          "Q", "R", "S", "Ş", "T", "U", "Ü", "V", "W", "X", "Y", "Z"
        ],
        specialChars: {
          "A": ["Â", "À", "Á", "Ã", "Ä", "Å"],
          "İ": ["Î", "Ì", "Í", "Ï"],
          "U": ["Û", "Ù", "Ú"],
          "E": ["È", "É", "Ê", "Ë"],
          "Y": ["Ý"]
        }
      }
    },
    created() {
      this.lives = this.player.appSettings.maxMove;
      this.oynaKazan = JSON.parse(sessionStorage.getItem("oynaKazanUnplayedGames"))
      this.startGame()
    },
    beforeMount() {
      window.addEventListener("beforeunload", this.preventNav)
    },
    beforeRouteLeave(to, from, next) {
      if (this.lockPage && this.gamePlay) {
        this.$modal.show('dialog', {
          class: "modal px-6 py-10",
          title: this.leaveMessage,
          buttons: [{
              title: "Evet",
              class: "btn btn--primary btn--sm mr-4",
              handler: () => {
                var gamePlay = this.$store.state.gamePlay
                gamePlay.moveCount = this.moveCount
                gamePlay.move = this.moves
                gamePlay.status = 'a';

                this.finalizeGame(gamePlay).then((response) => {
                  let resultGamePlay = response.data.result.set
                  if (resultGamePlay.difficulty == 0) {
                    this.oynaKazan = JSON.parse(sessionStorage.getItem("oynaKazanUnplayedGames"))
                  }
                  next();
                })

                return;
              }
            },
            {
              title: "Hayır",
              class: "btn btn--secondary btn--sm",
              handler: () => {
                this.$modal.hide('dialog')
              }
            }
          ]
        })
      } else {
        next();
      }
    },
    beforeDestroy() {
      this.$modal.hideAll();
      window.removeEventListener("beforeunload", this.preventNav);
    },
    methods: {
      ...mapActions(["createGame", "getWord", "usePowerup", "finalizeGame", "updateGame", "getGlobalStanding",
        "purchaseStart"
      ]),
      ...mapGetters(["checkTicket", "hasGamePlay"]),
      preventNav(event) {
        if (!this.lockPage) return;
        event.preventDefault()
        event.returnValue = ""
      },
      onClickHintTabItem(index) {
        if (!this.showHints) this.showHints = true;
        this.activeHint = index;
      },
      onClickCharacter(character, powerup = null) {
        // let isFirstMove = this.moves.length == 0

        this.moveCount++
        this.moves.push(powerup ? powerup : character)
        if (character == "I" && this._.indexOf(this.wordArray, character) < 0 && this._.indexOf(this.wordArray, "İ") >=
          0 && this.moves.indexOf("İ") < 0 && this.usedPowerupsLetters.indexOf("İ") < 0) {
          this.openedIChar = true;
          this.wrongCharacters.push(character);
          character = "İ";
          this.moves.push(character);
        }

        let isHit = this._.indexOf(this.wordArray, character) >= 0 || this._.intersection(this.wordArray, this
          .specialChars[character]).length > 0;

        if (isHit) {

          this.rightCharacters.push(character);

          for (let i = 0; i < this.wordSplit.length; i++) {
            for (let x = 0; x < this.wordSplit[i].length; x++) {
              if (this.wordSplit[i][x] == character) this.guess[i].splice(x, 1, character);
              if (this.specialChars[character]) {
                for (let a = 0; a < this.specialChars[character].length; a++) {
                  if ((this.wordSplit[i][x] == this.specialChars[character][a])) this.guess[i].splice(x, 1, this
                    .specialChars[character][a]);
                }
              }
            }
          }

          if (this.gameType != 0) {
            this.score += this.rightAnswerPoint;
          }
          if (this._.isEqual(this.wordSplit, this.guess)) {
            this.gameStatus = 'w';
            this.feedGame().then(() => {
              this.endGame();
            })
          } else {
            this.feedGame()
          }
        } else {
          this.wrongCharacters.push(character);
          if (this.lives == 1) {
            this.gameStatus = 'l';
            this.feedGame().then(() => {
              this.endGame();
            })
          } else {
            this.feedGame()
          }

          this.lives--;
        }
      },
      onClickPlayAgain() {
        this.$modal.hide("game-result-modal");
        if (this.gameType == 0) {
          localStorage.setItem("atGame", 'true')
          this.$router.push({
            name: "game",
            params: {
              id: this.oynaKazan[0].id
            },
            query: this.$route.query
          })
          this.$router.go(0)
        } else {
          let list = document.getElementsByClassName("oynakazanHide")
          for (let item of list) {
            item.style.display = '';
          }
          this.reCreateGame();
        }
      },
      onClickPurchaseAgain() {
        this.$modal.hide("game-result-modal");
        this.purchaseStart({
          transactionId: this.$route.query.transactionId,
          qty: 1,
          productId: 29
        }).then((response) => {
          if (window.webkit != undefined) {
            try {
              window.webkit.messageHandlers.receiveMessageFromJS.postMessage(
                '{"type":"payment", "merchantId":"601", "transactionId":"' + this.$route.query.transactionId +
                '","productId":"oynakazan.X.0000"}')
              console.log("webkit Var");
            } catch (e) {
              this.$refs.resultModal.message = response.message;
              this.$refs.resultModal.success = false
              this.$refs.resultModal.show()
            }
          } else if (window.appInterface != undefined) {
            try {
              window.appInterface.postMessage('{"type":"payment", "merchantId":"601", "transactionId":"' + this
                .$route.query
                .transactionId + '","productId":"oynakazan.X.0000"}')
              console.log("appInterface Var");

            } catch (e) {
              this.$refs.resultModal.message = response.message;
              this.$refs.resultModal.success = false
              this.$refs.resultModal.show()
            }
          } else {
            this.$refs.resultModal.message = "Üzgünüz, bir sorun oluştu. Lütfen daha sonra tekrar deneyin.";
            this.$refs.resultModal.success = false
            this.$refs.resultModal.show()
          }

        }).catch((error) => {
          this.$refs.resultModal.message = error.message;
          this.$refs.resultModal.success = false
          this.$refs.resultModal.show()
        })
      },
      onClickPowerup(powerup) {
        if (powerup.id == 'c') {
          if (this.clueCount > 0 || this.player.coins >= powerup.value) {
            this.usePowerup({
              id: this.$store.state.gamePlay.id,
              key: "open"
            }).then(() => {
              let wordCharacters = this._.map(this.wordArray, character => {
                if (this.flattenSpecialChars.includes(character)) {
                  let equalChar = this._.findKey(this.specialChars, charList => {
                    return charList.includes(character)
                  })

                  if (equalChar) {
                    return equalChar
                  }
                }

                return character
              })

              let leftCharacters = this._.difference(wordCharacters, this.rightCharacters);
              this._.pull(leftCharacters, " ");
              let char = leftCharacters[this._.random(0, leftCharacters.length - 1)]
              this.usedPowerupsLetters.push(char)
              this.onClickCharacter(char, 'open')
            })
          } else {
            this.$modal.show('dialog', {
              class: "modal px-6 py-10",
              title: "Üzgünüz, Yetersiz Bakiye.",
              buttons: [{
                title: "Mağazaya Git",
                class: "btn btn--primary btn--sm mr-4",
                handler: () => {
                  this.$modal.hide('dialog')
                  this.$modal.show("store-modal");
                  return;
                }
              }
              ]
            })
          }
        }

        if (powerup.id == 'r') {
          if (this.rewindCount > 0 || this.player.coins >= powerup.value) {
            if (this.openedIChar && this.wrongCharacters.includes('I') && this.wrongCharacters.length == 1) {
              return
            }
            if (this.wrongCharacters.length > 0) {
              this.usePowerup({
                id: this.$store.state.gamePlay.id,
                key: "rewind"
              }).then(() => {
                if (this.lives < 7) {
                  this.lives++
                }
                let spliceStart = this.wrongCharacters.length - 1
                if (this.openedIChar) {
                  if (spliceStart == this.wrongCharacters.indexOf('I')) {
                    spliceStart -= 1
                  }
                  if (spliceStart < 0) {
                    spliceStart += 2
                  }
                }
                this.wrongCharacters.splice(spliceStart, 1)
                this.moves.push('rewind')
              })

              this.feedGame()
            }
          } else {
            this.$modal.show('dialog', {
              class: "modal px-6 py-10",
              title: "Üzgünüz, Yetersiz Bakiye.",
              buttons: [{
                title: "Mağazaya Git",
                class: "btn btn--primary btn--sm mr-4",
                handler: () => {
                  this.$modal.hide('dialog')
                  this.$modal.show("store-modal");
                  return;
                }
              }
              ]
            })
          }
        }
        if (powerup.id == 'p') {
          if (this.changeCount > 0 || this.player.coins >= powerup.value) {
            this.usePowerup({
              id: this.$store.state.player.id,
              key: "change",
              params: {
                gameplay: this.$store.state.gamePlay.id
              }
            }).then(() => {
              this.feedAllEvent('powerup_usage', null, 'gameplay', 'change', 'change')
              this.startGame()
            })
          } else {
            this.$modal.show('dialog', {
              class: "modal px-6 py-10",
              title: "Üzgünüz, Yetersiz Bakiye.",
              buttons: [{
                title: "Mağazaya Git",
                class: "btn btn--primary btn--sm mr-4",
                handler: () => {
                  this.$modal.hide('dialog')
                  this.$modal.show("store-modal");
                  return;
                }
              }
              ]
            })          }
        }

        if (powerup.id == "h") {
          if ((this.hintCount > 0 || this.player.coins >= powerup.value)) {
            if (this.hints.length > 0 && this.usedHints.length < this.hints.length) {
              this.usePowerup({
                id: this.$store.state.gamePlay.id,
                key: "hint",
                params: {
                  gameplay: this.$store.state.gamePlay.id
                }
              }).then(() => {
                if (this.usedHints.length < this.hints.length) {
                  this.usedHints.push(this.usedHints.length);
                  this.activeHint = this.usedHints.length - 1;
                  this.showHints = true;
                }
                this.feedGame()
              })
            }
          } else {
            this.$modal.show('dialog', {
              class: "modal px-6 py-10",
              title: "Üzgünüz, Yetersiz Bakiye.",
              buttons: [{
                title: "Mağazaya Git",
                class: "btn btn--primary btn--sm mr-4",
                handler: () => {
                  this.$modal.hide('dialog')
                  this.$modal.show("store-modal");
                  return;
                }
              }
              ]
            })
          }
        }
      },
      reCreateGame() {
        if (this.checkTicket()) {
          this.createGame({
            gameType: this.gameType
          }).then(() => {
            this.startGame()
          })
        } else {
          this.startGame()
        }
      },
      decryptWord(cryptedWord) {
        let d1 = new Buffer(cryptedWord, 'base64')
        let d2 = d1.toString().split("").reverse().join("");
        return new Buffer(d2, 'base64')
      },
      retrieveWord() {
        this.getWord({
          gameType: this.gameType,
          gameId: this.gameId
        }).then((wordToGuess) => {
          let decryptedWord = this.decryptWord(wordToGuess.word)
          let word = Object.assign(wordToGuess, {
            word: decryptedWord.toString(),
            category: wordToGuess.category
          });
          this.payload = word
          this.$modal.hideAll()
          this.lives = 7
          this.score = 0
          this.wrongCharacters = []
          this.rightCharacters = []
          this.usedPowerupsLetters = [];

            if (this.gamePlay && this.gamePlay.hint) {
              try {
                let hintHash = new Buffer(this.gamePlay.hint, 'base64');
                let hintObj = JSON.parse(hintHash.toString());
                this.hints = this._.values(hintObj);
                this.usedHints = this.gamePlay.usedHints || [];
              } catch (error) {
                this.hints = [];
              }
            }

          if(wordToGuess.moves != undefined){
            //let letters = wordToGuess.word.replace(/ /g, '').split('');
            //let opendCorrect = letters.filter(item => !wordToGuess.moves.includes(item))

            //let length = wordToGuess.moves.length - (letters.length - opendCorrect.length)

          }

          if (null != this.gamePlay) {
            this.moveCount = this.gamePlay.moveCount
            this.moves = this.gamePlay.move
            this.usedPowerupsLetters = this.gamePlay.lettersPurchased;

            let powerupKeys = ['open', 'rewind']
            let letterDesk = this.gamePlay.move.concat(this.usedPowerupsLetters)
            this._.forEach(letterDesk, (character) => {
              if (!powerupKeys.includes(character)) {
                let index = this._.indexOf(this.largeWordArray, character)
                if (index >= 0) {
                  this.rightCharacters.push(character)
                } else {
                  this.wrongCharacters.push(character)
                }
              }
            })

            if(this.wrongCharacters.length > 0){
              this.lives = this.lives - this.wrongCharacters.length
              if(this.rightCharacters.indexOf("İ")>-1 && this.wrongCharacters.indexOf("I")>-1){ this.lives++ }
            }
            if(this.rightCharacters.length > 0){ this.score = this.rightCharacters.length }

            if (this.gamePlay.difficulty == 0){
              this.gameType = 0
              let list = document.getElementsByClassName("oynakazanHide")
              for (let item of list) {
                item.style.display = 'none';
              }
              this.leaveMessage = `Oyna Kazan'dasın, oyundan ayrılırsan oyun kapanır ve sadece teselli ödülü alabilirsin. Oyundan ayrılmak istediğinize emin misiniz ?`
              this.$modal.show("oynakazan-info-modal");
              this.oynaKazan = JSON.parse(sessionStorage.getItem("oynaKazanUnplayedGames"))
            }
            // this.lives -= this.wrongCharacters.length + 1
          }
        })
      },

      startGame() {
        this.payload = null
        this.lockPage = true
        this.openedIChar = false
        if (this.gameId.length<4 && !this.checkTicket() && !this.hasGamePlay()) {
          this.$nextTick(() => {
            this.$modal.show('no-ticket-modal');
          })
        } else {
          if(this.$store.state.sessionToken == null) {
            let waithSession = window.setInterval( () => {
              if(this.$store.state.sessionToken != null) {
                clearInterval(waithSession);
                this.retrieveWord()
              }
            }, 200);
          } else {
            this.retrieveWord()
          }
        }
      },
      endGame() {
        this.lockPage = false

        let gamePlay = this.getGamePlaySnapshot()
        if(this.openedIChar) {
          let indexOfI = gamePlay.move.indexOf("I");
          if(indexOfI >= 0) {
            gamePlay.move.splice(indexOfI, 1);
          }
        }

        this.finalizeGame(gamePlay).then((response) => {
          let resultGamePlay = response.data.result.set
          if (resultGamePlay.difficulty == 0) {
            this.oynaKazan = JSON.parse(sessionStorage.getItem("oynaKazanUnplayedGames"))

            if (resultGamePlay.scoreProcessed == 0) {
              this.oynaKazanMessage =
                "Üzgünüz, paycell puanınız yüklenirken bir sorun oluştu. Lütfen yardım menüsünden OyunId: " +
                response.data.result.set.id + " ile yardım talebinde bulununuz";
              this.$modal.show("oynakazan-info-modal");
            }
          }

          if(this.lives < 0) {
            this.lives = 0
          }

          this.showHints = false;

          this.score = resultGamePlay.scoreEarned - this.bonus

          if(this.score < 0) {
            this.score = 0
          }

          this.renewLeaderBoard();
          this.$modal.show("game-result-modal")
        })
      },
      renewLeaderBoard() {
        this.standingArray = [];
        const currentDate = new Date();
        this.getGlobalStanding({
          player: this.player.id,
          date: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
        }).then((response) => {
          const userIndex = response.findIndex(element => element.player.id == this.player.id);
          let startNumber = (response.length - 1) === userIndex ? userIndex - 2 : userIndex - 1;
          let endNumber = userIndex > 0 ? userIndex + 2 : 3;
          for (let i = startNumber; i < endNumber; i++) {
            if (response[i] !== undefined) {
              this.standingArray.push(response[i]);
            }
          }
        })
      },
      async feedGame() {
        return new Promise((resolve, reject) => {
          let gamePlay = this.getGamePlaySnapshot()
          this.updateGame({
            id: this.$store.state.gamePlay.id,
            data: gamePlay
          }).then(() => {
            resolve()
          }).catch(() => {
            reject();
          })
        });

      },
      getGamePlaySnapshot() {
        var gamePlay = this.$store.state.gamePlay
        gamePlay.moveCount = this.moveCount
        gamePlay.moves = this.moves
        gamePlay.lettersPurchased = this.usedPowerupsLetters
        gamePlay.usedHints = this.usedHints;

        if (this.gameType == '1') {
          gamePlay.scoreEarned = this.totalScore
        }

        if (this.gameStatus == 'w') {
          gamePlay.coinsEarned = this.coinsEarned
        }

        return gamePlay
      }
    }
  }
</script>

<style>

</style>