<template>
  <modal
      name="league-game-modal"
      classes="modal"
      :minWidth="200"
      :minHeight="400"
      :maxWidth="480"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="true"
      :reset="true"
      width="90%"
      height="auto">
    <div class="flex justify-end relative" v-if="!this.$store.state.isLoading">
      <button @click="this.hide" class="modal-close absolute right-0"></button>
    </div>
    <div class="flex flex-col items-center h-full">
      <h2 class="text-center my-4">OYNA, EĞLEN, KAZAN!</h2>
      <div class="winner-stand flex flex-col items-center">
        <img :src="require('@/assets/winner-stand.svg')" alt="">
        <div class="ribbons">
          <div class="ribbons__item ribbons__item--silver">
            <span><b>10bin</b> Paycell Puan</span>
          </div>
          <div class="ribbons__item ribbons__item--gold">
            <span><b>15bin</b> Paycell Puan</span>
          </div>
          <div class="ribbons__item ribbons__item--bronze">
            <span><b>5bin</b> Paycell Puan</span>
          </div>
        </div>
      </div>
      <div class="modal-container">
        <div class="leaderboard leaderboard--single mx-auto px-4 mb-4">
          <div class="leaderboard__item" v-for="standingPlayer, i in standingPlayers" :key="`${standingPlayer.nickname}-${i}`" :class="{'active': standingPlayer.player.alias == player.alias}">
            <div class="leaderboard__item-number">{{ standingPlayer.currentRank ? `${standingPlayer.currentRank}.` : '-'}}</div>
            <div class="leaderboard__item-info">
              <div class="leaderboard__item-avatar">
                <img :src="standingPlayer.player.avatarUrl" alt="">
              </div>
              <div class="leaderboard__item-name">{{standingPlayer.player.alias}}</div>
            </div>
            <div class="leaderboard__item-points">{{standingPlayer.score ? standingPlayer.score : 0}}</div>
          </div>
        </div>
      </div>
      <div class="flex items-center mb-4">
        <button class="btn btn--primary btn--sm" @click.prevent="onClickCreateGame">OYNA</button>
        <!--<router-link :to="{name: 'game', params: {id: '1'}, query: this.$route.query}" class="btn btn--primary w-4/5">OYNA</router-link>-->
      </div>
    </div>
  </modal>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  computed: {
    ...mapState(['player'])
  },
  data() {
    return {
      standingPlayers: []
    }
  },
  methods: {
    ...mapActions(["createGame", "getGlobalStanding"]),
    prepareGlobalStanding() {
      var today = new Date();
      var day = String(today.getDate()).padStart(2, '0');
      var month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var year = today.getFullYear();

      let date = `${year}-${month}-${day}`

      this.getGlobalStanding({date: date}).then((response) => {
        this.standingPlayers = response
      })
    },
    show() {
      this.prepareGlobalStanding()
      this.$modal.show('league-game-modal')
    },
    hide() {
      this.$modal.hide('league-game-modal');
    },
    onClickCreateGame() {
      this.createGame({gameType: '1'}).then(() => {
        this.$router.push({name: "game", params: {id: '1'}, query: this.$route.query})
      })
    }
  }
}
</script>

<style>

</style>