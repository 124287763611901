<template>
  <div class="home">
    <div class="flex flex-col items-center justify-center p-8 guide">
      <h2>Nasıl Oynanır?</h2>
      <ul class="list-disc ml-5 mt-2">
        <li>
          Ana sayfada bulunan "Ödüllü lig" seçeneğine tıklayarak yeni bir oyun
          başlatabilirsin.
        </li>
        <li>
          Hedefin en az harfe basarak gizlenmiş kelimeyi doğru bir biçimde
          bulmaktır.
        </li>
        <li>
          Oyun rastgele bir kelime ile açılır. Sayfanın ortasında bu kelime ile
          ilgili ipucunu görebilirsin. Saklı kelime bir "futbol takımı", bir
          "eşya", "coğrafya" ile alakalı bir terim ya da başka bir şey ile
          ilgili olabilir.
        </li>
        <li>
          Kelimeyi açmak için 7 hakkın bulunmakta. İlk harf açılışında bir hak
          düşer. Bundan sonra her hatalı harf seçiminde bir hak düşer.
        </li>
        <li>
          Verilen maksimum tahmin hakkında doğru tahminde bulunamazsan oyun
          sonlanır ve o ana kadar kazandığın skorun ile koini toplarsın.
        </li>
        <li>
          Harflerden herhangi birine tıkladığında eğer harfin üzeri çiziliyorsa
          bu harf kelimede mevcut değil demektir. Doğru bir harfe tıkladığında
          bu harf kelimenin içinde görünür olur. Bu şekilde kelimeyi doğru
          bilene kadar harfleri açmaya devam edebilirsin. Oyun bittiğinde
          "Tekrar Oyna" butonuna tıklayarak yeni bir oyun başlatabilirsin.
        </li>
      </ul>

      <h2 class="mt-2">Ödüllü Lig</h2>
      <ul class="list-disc ml-5 mt-2">
        <li>
          Ödüllü lig seçeneğinde oyuna, satın alacağın bilet ile katılabilirsin.
          Günlük 5 ücretsiz bilet hakkın bulunmakta. Bu biletler tükendikten
          sonra ödüllü ligde daha fazla oynamaya ve kazanmaya devam etmek için
          mağazadan bilet alman gerekir.
        </li>
        <li>
          Oyunun sonunda kazandığın skorlar seni ligde üst sıralara taşır.
        </li>
        <li>
          Lig sonunda en yüksek skoru toplayan ilk 50 oyuncuya çeşitli dijital
          ödüller verilir.
        </li>
      </ul>

      <h2 class="mt-2">Oyna Kazan</h2>
      <ul class="list-disc ml-5 mt-2">
        <li>
          Oyna kazan seçeneğinde oyuna, satın alacağın tek oyun veya oyun paketleri ile katılabilirsin.
        </li>
        <li>
          Oyunun sonunda dijital ödüllerin hesabına yüklenir.
        </li>
      </ul>

      <h2 class="mt-6">Oyun İçerisindeki İkonlar Ve Anlamları</h2>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="icon-circle text-center mr-4 w-4">
          <img src="@/assets/icon-live.svg" alt="" />
        </div>
        <p><b>Can:</b> Kelimeyi doğru tahmin etmek için kalan hamle sayısı</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="icon-circle text-center mr-4">
          <img src="@/assets/icon-coin.svg" alt="" />
        </div>
        <p><b>Koin:</b> Oyun içi mağazada kullanılabilecek para birimi.</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="icon-circle text-center mr-4">
          <img src="@/assets/icon-ticket.svg" alt="" />
        </div>
        <p><b>Bilet:</b> Kullanıcının oyuna giriş bileti.</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="btn btn--xs btn--primary self-center mr-5 ml-1">
          <img src="@/assets/icon-relive.svg" alt="" />
        </div>
        <p><b>Geri Sarma:</b> En son seçilen yanlış harfi geri alır.</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="btn btn--xs btn--primary self-center mr-5 ml-1">
          <img src="@/assets/block-button.svg" alt="" />
        </div>
        <p><b>Harf Açma:</b> Kelime içerisinden bir harf açar.</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="btn btn--xs btn--primary self-center mr-5 ml-1">
          <img src="@/assets/icon-clue.svg" alt="" />
        </div>
        <p><b>İpucu:</b> Kelime ile ilgili ipuçları gösterir. (Eğer kelimeye ait ipucu var ise aktif olarak gözükür.)</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="icon-circle text-center mr-4">
          <img src="@/assets/icon-score.svg" alt="" />
        </div>
        <p><b>Skor:</b> Oyun içinde harf açtıkça toplanan puan.</p>
      </div>

      <h2 class="mt-2">Kurallar / Detaylar</h2>
      <ul class="list-disc ml-5 mt-2">
        <li>
          Hedefin en az harfe basarak gizlenmiş kelimeyi doğru bir biçimde
          bulmaktır.
        </li>
        <li>
          Oyun ekranında skorun, harf açma hakkın, toplam bilet sayın, toplam
          koin sayın, sahip olduğun güçlerin sayısı gösterilir.
        </li>
        <li>
          Kelimeyi açmak için 7 hakkın bulunmakta. İlk harf açışında bir hak
          düşer. Bundan sonra her hatalı harf seçiminde 1 hak düşer.
        </li>
        <li>
          Verilen maksimum tahmin hakkında doğru tahminde bulunamazsan oyun
          sonlanır ve o ana kadar kazandığın puanı toplarsın.
        </li>
        <li>
          Sana verilen tahmin hakkı içerisinde kelimeyi doğru olarak bilirsen
          başarı seviyene göre puanlar ve koinler kazanırsın.
        </li>
        <li>Her lig oyunu açmak 1 bilet değerindedir.</li>
        <li>
          Tüm oyuncular ligdeki mevcut puan ve sıralamalarını "skor tablosu"
          sayfasından takip edebilirsin.
        </li>
      </ul>

      <h2 class="mt-2">Ligler</h2>
      <ul class="list-disc ml-5 mt-2">
        <li>
          Ligler günlük olarak düzenlenmektedir. Saat 00:00'da sıralama kaydedilir ve kullanıcılara ait puanlar sıfırlanır.
        </li>
        <li>Ligler kapandıktan sonraki gün sabah saat 09:00'da kullanıcıların bir gün önceki sıralamalarından kazandıkları ödüller hesaplarına yüklenir.</li>
        <li>
          Gün sonunda aynı puana sahip kullanıcılar oyun oynama çokluğuna göre daha avantajlı sayılır. Aynı puana sahip ve aynı sırada bulunan diğer kullanıcılara göre daha üst sıraya yerleşirler.
        </li>
      </ul>

      <h2>Ödüller</h2>
      <table class="table w-4/5 mt-2">
        <tbody>
          <tr>
            <td>
              <div>
                <div><p>1. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>15.000 Paycell Puan</li>
                    <li>200 koin</li>
                    <li>5 x geri sarma gücü</li>
                    <li>5 x harf açma gücü</li>
                    <li>5 x ipucu gücü</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>2. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>10.000 Paycell Puan</li>
                    <li>150 koin</li>
                    <li>5 x geri sarma gücü</li>
                    <li>4 x harf açma gücü</li>
                    <li>5 x ipucu gücü</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>3. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>5.000 Paycell Puan</li>
                    <li>100 koin</li>
                    <li>4 x geri sarma gücü</li>
                    <li>3 x harf açma gücü</li>
                    <li>4 x ipucu gücü</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>4. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2.500 Paycell Puan</li>
                    <li>4 x geri sarma gücü</li>
                    <li>3 x harf açma gücü</li>
                    <li>4 x ipucu gücü</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>5. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2.500 Paycell Puan</li>
                    <li>4 x geri sarma gücü</li>
                    <li>3 x harf açma gücü</li>
                    <li>4 x ipucu gücü</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>6. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2.000 Paycell Puan</li>
                    <li>4 x geri sarma gücü</li>
                    <li>3 x harf açma gücü</li>
                    <li>4 x ipucu gücü</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>7. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2.000 Paycell Puan</li>
                    <li>4 x geri sarma gücü</li>
                    <li>3 x harf açma gücü</li>
                    <li>4 x ipucu gücü</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>8. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1.000 Paycell Puan</li>
                    <li>4 x geri sarma gücü</li>
                    <li>3 x harf açma gücü</li>
                    <li>4 x ipucu gücü</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>9. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1.000 Paycell Puan</li>
                    <li>4 x geri sarma gücü</li>
                    <li>3 x harf açma gücü</li>
                    <li>4 x ipucu gücü</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>10. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1.000 Paycell Puan</li>
                    <li>4 x geri sarma gücü</li>
                    <li>3 x harf açma gücü</li>
                    <li>4 x ipucu gücü</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>11. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>3 x geri sarma gücü </li>
                    <li>3 x harf açma gücü </li>
                    <li>3 x ipucu gücü</li>
                    <li>500 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>12. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>3 x geri sarma gücü </li>
                    <li>3 x harf açma gücü </li>
                    <li>3 x ipucu gücü</li>
                    <li>500 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>13. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>3 x geri sarma gücü </li>
                    <li>3 x harf açma gücü </li>
                    <li>3 x ipucu gücü</li>
                    <li>500 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>14. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>3 x geri sarma gücü </li>
                    <li>3 x harf açma gücü </li>
                    <li>3 x ipucu gücü</li>
                    <li>500 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>15. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>3 x geri sarma gücü </li>
                    <li>3 x harf açma gücü </li>
                    <li>3 x ipucu gücü</li>
                    <li>500 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>16. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2 x geri sarma gücü </li>
                    <li>2 x harf açma gücü </li>
                    <li>2 x ipucu gücü</li>
                    <li>400 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>17. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2 x geri sarma gücü </li>
                    <li>2 x harf açma gücü </li>
                    <li>2 x ipucu gücü</li>
                    <li>400 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>18. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2 x geri sarma gücü </li>
                    <li>2 x harf açma gücü </li>
                    <li>2 x ipucu gücü</li>
                    <li>400 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>19. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2 x geri sarma gücü </li>
                    <li>2 x harf açma gücü </li>
                    <li>2 x ipucu gücü</li>
                    <li>400 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>20. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2 x geri sarma gücü </li>
                    <li>2 x harf açma gücü </li>
                    <li>2 x ipucu gücü</li>
                    <li>400 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>21. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2 x geri sarma gücü </li>
                    <li>2 x harf açma gücü </li>
                    <li>2 x ipucu gücü</li>
                    <li>300 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>22. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2 x geri sarma gücü </li>
                    <li>2 x harf açma gücü </li>
                    <li>2 x ipucu gücü</li>
                    <li>300 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>23. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2 x geri sarma gücü </li>
                    <li>2 x harf açma gücü </li>
                    <li>2 x ipucu gücü</li>
                    <li>300 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>24. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2 x geri sarma gücü </li>
                    <li>2 x harf açma gücü </li>
                    <li>2 x ipucu gücü</li>
                    <li>300 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>25. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>2 x geri sarma gücü </li>
                    <li>2 x harf açma gücü </li>
                    <li>2 x ipucu gücü</li>
                    <li>300 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>26. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x geri sarma gücü </li>
                    <li>1 x harf açma gücü </li>
                    <li>1 x ipucu gücü</li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>27. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x geri sarma gücü </li>
                    <li>1 x harf açma gücü </li>
                    <li>1 x ipucu gücü</li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>28. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x geri sarma gücü </li>
                    <li>1 x harf açma gücü </li>
                    <li>1 x ipucu gücü</li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>29. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x geri sarma gücü </li>
                    <li>1 x harf açma gücü </li>
                    <li>1 x ipucu gücü</li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>30. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x geri sarma gücü </li>
                    <li>1 x harf açma gücü </li>
                    <li>1 x ipucu gücü</li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>31. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x harf açma gücü </li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>32. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x harf açma gücü </li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>33. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x harf açma gücü </li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>34. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x harf açma gücü </li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>35. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x harf açma gücü </li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>36. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x ipucu gücü</li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>37. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x ipucu gücü</li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>38. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x ipucu gücü</li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>39. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x ipucu gücü</li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>40. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>200 koin</li>
                    <li>1 x ipucu gücü</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>41. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x geri sarma gücü </li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>42. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x geri sarma gücü </li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>43. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x geri sarma gücü </li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>44. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x geri sarma gücü </li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>45. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>1 x geri sarma gücü </li>
                    <li>200 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>46. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>150 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>47. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>150 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>48. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>150 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>49. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>150 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div><p>50. Sıra</p></div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <ul>
                    <li>150 koin</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      content: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.table {
  border: 1px solid #C4C4C4;
  text-align: left;

  tr {
    td, th {
      border-bottom: 1px solid #C4C4C4;
      border-right: 1px solid #C4C4C4;
      padding: 5px 15px;
    }  
  }
}
</style>
