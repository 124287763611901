<template>
  <modal v-if="this.$store.state.player" name="no-ticket-modal" classes="modal" :minWidth="200"
         :minHeight="400"
         :maxWidth="480"
         :scrollable="true"
         :reset="true"
         :adaptive="true"
         width="90%"
         height="auto"
         :clickToClose="false">
    <div class="flex flex-col items-center">
      <h1 class="my-4 font-semibold">ÜZGÜNÜZ !</h1>
      <img class="mb-4" src="@/assets/icon-sad.svg" alt="">
      <h3>Oynamak için yeterli biletiniz yok.</h3>
      <div class="flex justify-center items-center mb-8">
        <div class="icon-circle mr-4">
          <img src="@/assets/icon-ticket.svg" alt="">
        </div>
        <span class="text-2xl font-semibold">BİLET : {{ this.$store.state.player.powerups.ticket }}</span>
      </div>
      <div class="flex items-center mb-4">
        <router-link :to="{name: 'dashboard', query: this.$route.query}" v-if="this.$route.name != 'dashboard'" class="btn btn--secondary btn--sm">ANA
          MENÜ
        </router-link>
        <button v-if="this.$route.name == 'dashboard'" @click="this.hide" class="btn btn--secondary btn--sm">ANA
          MENÜ
        </button>
        <router-link :to="{name: 'store-order', query: this.$route.query, params: {order: 'ticket'}}" class="btn btn--primary -ml-2">MAĞAZAYA GİT
        </router-link>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "NoTicketModal",
  methods: {
    show() {
      this.$modal.show('no-ticket-modal')
    },
    hide() {
      this.$modal.hide('no-ticket-modal');
    },
  }
}
</script>

<style scoped>

</style>