<template>
  <div>
    <modal
        name="network-status-modal"
        classes="modal"
        :minWidth="200"
        :minHeight="400"
        :maxWidth="480"
        :scrollable="true"
        :adaptive="true"
        :reset="true"
        width="100%"
        :clickToClose="false"
        height="auto">
      <div class="flex flex-col m-5">
        <h1 class="text-center">İnternet bağlantınız kesildi.</h1>
        <h2 class="text-center">Lütfen bağlantınızı kontrol ediniz</h2>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  methods: {
    show() {
      this.$modal.show("network-status-modal");
    },
    hide() {
      this.$modal.hide("network-status-modal");
    }
  }
}
</script>