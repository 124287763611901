<template>
  <div class="currencies-wrapper">
    <div class="currencies">
      <div class="oynakazanHide currencies__item" @click="onClickCurrencyItem">
        <div class="currencies__item-img"><img :src="require('@/assets/ticket.svg')" alt=""></div>
        <div class="currencies__item-count">
          {{getPlayerTickets}}
        </div>
      </div>
      <div class="currencies__item"  @click="onClickCurrencyItem">
        <div class="currencies__item-img"><img :src="require('@/assets/coin.svg')" alt=""></div>
        <div class="currencies__item-count">
          {{player.coins}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  components: {
  },
  computed: {
    ...mapState(["player"]),
    getPlayerTickets() {
      return this.player.isImmortal ? '∞' : this.player.powerups.ticket
    }
  },
  methods: {
    onClickCurrencyItem() {
      if(this.$route.name != 'store') {
        this.$router.push({name: 'store', query: this.$route.query})
      }
    }
  }
}
</script>

<style>

</style>