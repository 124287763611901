<template>
  <div class="home m-9">
    <div class="flex flex-col items-center justify-center p-4" v-html="content.content">
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  components: {},
  data() {
    return {
      content: ""
    }
  },
  methods: {
    ...mapActions(["getContent"])
  },
  created() {
    this.getContent(3).then((response) => {
      this.content = response;
    })
  },
}
</script>
<style scoped>

</style>