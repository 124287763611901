import request from './request'

const auth = {
  init(environment) {
    return request.get(`/init/${environment}`)
  },

  paycellLogin(playerId) {
    return request.get(`/player/login/paycell`, {headers: {'X-Device-Id': playerId}})
  }
}

export default auth