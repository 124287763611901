<template>
  <div id="app">
    <router-view/>
    <store-modal ref="storeModal"></store-modal>
    <network-status-modal ref="networkStatusModal"></network-status-modal>
    <maintenance-modal ref="maintenanceModal"></maintenance-modal>
  </div>
</template>
<script>
import StoreModal from "./views/components/StoreModal";
import NetworkStatusModal from "./views/components/NetworkStatusModal";
import MaintenanceModal from "./views/components/MaintenanceModal";
import { mapState } from 'vuex';

export default {
  components: {StoreModal, NetworkStatusModal, MaintenanceModal},
  data(){
    return {
      networkStatus : navigator.onLine
    }
  },
  created() {
    window.addEventListener('online', this.updateConnectionStatus);
    window.addEventListener('offline', this.updateConnectionStatus);
  },
  computed: {
    ...mapState(["inMaintenance"])
  },
  methods: {
    updateConnectionStatus() {
      this.networkStatus = navigator.onLine
    }
  },
  watch:{
    networkStatus: function (val) {
      if(val === false) {
        this.$refs.networkStatusModal.show();
      }else if(val === true){
        this.$refs.networkStatusModal.hide()
      }
    },
    "inMaintenance": function (val) {
      if(val === false) {
        this.$refs.maintenanceModal.hide()
      } else if(val === true){
        this.$refs.maintenanceModal.show()
      }
    }
  }
}
</script>