import Vue from 'vue';
import Axios from 'axios';
import store from '@/store'

Axios.defaults.baseURL = process.env.API_LOCATION
Axios.defaults.headers.common.Accept = '*/*'
Axios.defaults.headers.common['Content-Type'] = 'application/json'

let apiKey = 'bd552f72ea289d192144ddeb35d27804'
let devApiKey = 'bd552f72ea289d192144ddeb35d27803';
//const API_DEV_LOCATION = "http://127.0.0.1:8090/v1"
const API_DEV_LOCATION = "https://rest.api.dev.adam-asmaca.com/v1"
// const API_DEV_LOCATION = "https://rest.api.paycell.adam-asmaca.com/v1"
const API_PROD_LOCATION = "https://rest.api.paycell.adam-asmaca.com/v1"

const instance = Axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? API_DEV_LOCATION : API_PROD_LOCATION
});

instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  if(null != store.state.sessionToken) {
    config.headers['X-Session-Token'] = store.state.sessionToken
  }else{
    config.headers['X-Api-Key'] = process.env.NODE_ENV == 'development' ? devApiKey : apiKey;
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

instance.interceptors.response.use(
  function(response) {
    if(response.data.sessionToken !== null) {
      store.dispatch("setSessionToken", response.data.sessionToken);
      store.dispatch("setMaintenanceMode", false);
    }
    return response;
  }, 
  function(error) {
      //handle error
      if(error.response) {
          switch (error.response.status) {
              case 401:
                  if(error.response.data.code == '401.0000') {
                      localStorage.removeItem('sessionToken');
                      window.location = '/';
                  }else{
                      return Promise.reject(error);
                  }
                  break;
              case 503:
                if(error.response.data.code == '503.0000') {
                  store.dispatch("setMaintenanceMode", true);
                }else{
                    return Promise.reject(error);
                }
                break;
              default:
                  return Promise.reject(error);
          }
      }
      else {
        return Promise.reject(error);
      }
  }
);

Vue.$http = instance

Object.defineProperty(Vue.prototype, '$http', {
  get() {
    return Axios
  },
})

export default instance;