import Vue from 'vue'
import VueRouter from 'vue-router'

import LayoutMain from '@/views/layouts/Main'
import Dashboard from '@/views/pages/Dashboard.vue'
import Leaderboard from '@/views/pages/Leaderboard.vue'
import Game from '@/views/pages/Game.vue'
import StorePage from '@/views/pages/Store.vue'
import Settings from '@/views/pages/Settings.vue'
import UnderConstruction from '@/views/pages/UnderConstruction.vue'
import PrivateArea from '@/views/pages/PrivateArea.vue'
import MaintenanceArea from '@/views/pages/MaintenanceArea.vue'
import Help from '@/views/pages/Help.vue'
import Guide from '@/views/pages/Guide.vue'
import Privacy from '@/views/pages/Privacy';
import PrivacyKvkk from '@/views/pages/PrivacyKvkk';

Vue.use(VueRouter)

const routes = [
  // =============================================================================
  // MAIN LAYOUT ROUTES
  // =============================================================================
  {
    path: '/',
    component: LayoutMain,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: '/leaderboard',
        name: 'leaderboard',
        component: Leaderboard
      },
      {
        path: '/store',
        name: 'store',
        component: StorePage
      },
      {
        path: '/store/:order',
        name: 'store-order',
        component: StorePage,
        props: true
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings
      },
      {
        path: '/help',
        name: 'help',
        component: Help
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: Privacy
      },
      {
        path: '/kvkk',
        name: 'kvkk',
        component: PrivacyKvkk
      },
      {
        path: '/guide',
        name: 'guide',
        component: Guide
      },
      {
        path: '/not-found',
        name: 'not-found',
        component: UnderConstruction
      }
    ]
  },
  {
    path: '/game/:id',
    name: 'game',
    component: Game,
    props: route => ({ gameId: route.params.id })
  },
  {
    path: '*',
    component: LayoutMain,
    redirect: '/',
    children: [
      {
        path: '/maintenance',
        name: 'MaintenanceMode',
        component: MaintenanceArea
      },
      {
        path: '/not-found',
        name: 'NotFound',
        component: PrivateArea
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // If externalId is not given, navigate to not found page.
  if(
    (
      undefined == to.query.externalId || 
      null == to.query.externalId || 
      undefined == to.query.transactionId || 
      null == to.query.transactionId || 
      "" == to.query.transactionId ||
      "" == to.query.externalId
    ) && 'NotFound' != to.name
    ) {
    next({
      name: 'NotFound'
    })
  }

  next()
});

export default router
