<template>
  <div class="h-full flex flex-col" style="z-index: 999">
    <div class="store-tabs flex">
      <div @click="onClickStoreTab(`section-${i+1}`)" :ref="`tab${i+1}`" :class="{'active': activeTab == `tab${i+1}`}"
           class="store-tabs__item" v-for="(productGroup, i) in filteredProductGroups"
           :key="`store-tab-${productGroup.id}`">
        <img v-if="productGroup.id == 1" src="@/assets/icon-coin.svg" alt="">
        <img v-if="productGroup.id == 2" src="@/assets/icon-ticket.svg" alt="">
        <img v-if="productGroup.id == 3" src="@/assets/icon-relive.svg" alt="">
        <img v-if="productGroup.id == 4" src="@/assets/store/chest-immortal.svg" alt="">
      </div>
      <!-- <div @click="onClickStoreTab('section-2')" ref="tab2" :class="{'active': activeTab == 'tab2'}"
        class="store-tabs__item">
        <img src="@/assets/icon-ticket.svg" alt="">
      </div>
      <div @click="onClickStoreTab('section-3')" ref="tab3" :class="{'active': activeTab == 'tab3'}"
        class="store-tabs__item">
        <img src="@/assets/icon-relive.svg" alt="">
      </div>
      <div @click="onClickStoreTab('section-4')" ref="tab4" :class="{'active': activeTab == 'tab4'}"
        class="store-tabs__item">
        <img src="@/assets/store/chest-immortal.svg" alt="">
      </div> -->
    </div>
    <h1 class="font-semibold text-center py-4 flex-shrink-0">MAĞAZA</h1>
    <div id="store-content" class="flex flex-col items-center px-4 overflow-scroll">
      <p class="text-center text-red-600" v-if="null != this.errorMsg">{{ this.errorMsg }}</p>
      <div :id="`section-${i+1}`" class="mb-8 w-full" v-for="(productGroup, i) in filteredProductGroups"
           :key="`${productGroup.id}-${productGroup.title}`">
        <h3 class="text-center">{{ productGroup.title }}</h3>
        <div class="grid gap-4 grid-cols-2 w-full">
          <div class="store-item justify-self-center	" :class="{'col-span-2': product.type == 'm'}"
               @click="beginTransaction(product)" v-for="(product) in productGroup.products"
               :key="`${product.id}-${product.sku}`">
            <div class="store-item__img" v-if="product.type == 'c'">
              <img :src="product.image" alt="title">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.coins }}</span>
            </div>
            <div class="store-item__img" v-if="product.type == 't'">
              <img :src="product.image" alt="title">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.ticket }}</span>
            </div>
            <div class="store-item__img" v-if="product.type == 'o'">
              <img :src="require(`@/assets/store/ticket-immortal.svg`)" alt="">
              <span class="text-stroke">
                <small>+</small>
                <template>
                  {{ intervalDateToString(product.rewardInfo.powerups.immortality.validUntil) }}
                </template>
              </span>
            </div>
            <div class="store-item__img" v-if="product.type == 'p'">
              <template v-if="product.rewardInfo.powerups.rewind > 0">
                <img :src="product.image" :alt="product.title">
                <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
              </template>
              <template v-if="product.rewardInfo.powerups.hint > 0">
                <img :src="product.image" :alt="product.title">
                <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.hint }}</span>
              </template>
              <template v-if="product.rewardInfo.powerups.open > 0">
                <img :src="product.image" :alt="product.title">
                <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
              </template>
            </div>
            <div class="store-item__img store-item__img--chest" v-if="product.type == 'm'">
              <template v-if="product.rewardInfo.powerups.immortality.isActive">
                <img class="mb-2" :src="product.image" :alt="product.title">
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.rewind > 0">
                  <img :src="require(`@/assets/store/rewind.svg`)" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
                </div>
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.open > 0">
                  <img :src="require(`@/assets/store/open.svg`)" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
                </div>
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.hint > 0">
                  <img :src="require(`@/assets/store/hint.svg`)" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.hint }}</span>
                </div>
                <div class="store-item__chest-item flex items-center">
                  <img :src="require(`@/assets/store/ticket-immortal.svg`)" class="mr-2" alt="">
                  <span class="text-stroke">
                    <small>+</small>
                    <template>
                      {{ intervalDateToString(product.rewardInfo.powerups.immortality.validUntil) }}
                    </template>
                  </span>
                </div>
              </template>
              <template v-else>
                <img class="mb-2" :src="product.image" :alt="product.title">
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.rewind > 0">
                  <img :src="require(`@/assets/store/rewind.svg`)" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
                </div>
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.open > 0">
                  <img :src="require(`@/assets/store/open.svg`)" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
                </div>
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.hint > 0">
                  <img :src="require(`@/assets/store/hint.svg`)" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.hint }}</span>
                </div>
              </template>
            </div>
            <div class="store-item__value">
              <span class="store-item__value-number">{{ product.price }}</span>
              <span class="store-item__value-currency">
                <template v-if="product.currency == 'TRY'">TL</template>
                <template v-else>
                  <img src="@/assets/coin-small.svg" alt="">
                </template>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <result-modal ref="resultModal"></result-modal>
    <modal name="suggest-product-modal" classes="modal" :minWidth="200" :minHeight="400" :maxWidth="480"
           :scrollable="true"
           :adaptive="true" :reset="true" width="90%" height="auto">
      <button @click="$modal.hide('suggest-product-modal')" class="absolute right-0 mr-6 mt-6">
        <img src="@/assets/times-black.svg" alt="">
      </button>
      <div class="flex flex-col items-center">
        <h1 class="font-semibold text-center mt-8 flex">Yetersiz Koin<img class="mx-1" src="@/assets/icon-coin.svg"
                                                                          alt="">!</h1>
        <p>Dilersen ihtiyacın olan Koini satın alabilirsin.</p>
        <template v-if="suggestedProduct">
          <div class="w-3/5 mt-2 mb-4 flex justify-center">
            <div class="store-item store-item--inactive justify-self-center">
              <div class="store-item__img"><img :src="suggestedProduct.image"
                                                alt="title"><span
                  class="text-stroke"><small>x</small>{{ suggestedProduct.rewardInfo.coins }}</span></div>
              <div class="store-item__value"><span class="store-item__value-number">{{
                  suggestedProduct.price
                }}</span><span
                  class="store-item__value-currency">TL</span></div>
            </div>
          </div>
          <button class="btn btn--primary self-center mb-5" @click="beginTransaction(suggestedProduct)">
            SATIN AL
          </button>
        </template>
      </div>

    </modal>

  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from "vuex"
import ResultModal from '@/views/components/ResultModal'

export default {
  components: {
    ResultModal
  },
  data() {
    return {
      errorMsg: null,
      activeTab: null,
      suggestedProduct: null,
    }
  },
  props: {
    mode: {
      type: String,
      default: "page"
    },
    order: String,
  },
  computed: {
    ...mapState(["productGroups", "player"]),
    filteredProductGroups() {
      if (this.order && this.order == "ticket") {
        let productGroupsCopy = this._.cloneDeep(this.productGroups);
        const ticketProducts = productGroupsCopy.find(g => g.id == "2");
        productGroupsCopy.splice(productGroupsCopy.indexOf(ticketProducts), 1);
        productGroupsCopy.splice(0, 0, ticketProducts);
        return productGroupsCopy;
      }
      return this.productGroups;
    }
  },
  mounted() {
    this.retrieveProductGroups().then(() => {
      this.$nextTick(() => {
        this.checkActiveTab();
        if (this.mode == 'modal') {
          document.getElementById("store-content").addEventListener("scroll", this.checkActiveTab)
        } else {
          window.addEventListener("scroll", this.checkActiveTab)
        }
      })
    });
  },
  beforeDestroy() {
    if (this.mode == 'modal') {
      document.getElementById("store-content").removeEventListener("scroll", this.checkActiveTab)
    } else {
      window.removeEventListener("scroll", this.checkActiveTab);
    }
  },
  methods: {
    ...mapActions(["buyCoins", "buyTickets", "purchaseStart", "purchaseProduct", "retrieveProductGroups"]),
    checkActiveTab() {
      let section2 = document.getElementById("section-2").offsetTop - 40;
      let section3 = document.getElementById("section-3").offsetTop - 40;
      let section4 = document.getElementById("section-4").offsetTop - 40
      let windowTop = window.top.scrollY + 115
      if (this.mode == 'modal') {
        windowTop = document.getElementById("store-content").scrollTop + 115;
      }

      if (0 < windowTop && windowTop < section2) {
        this.activeTab = 'tab1'
      } else if (section2 <= windowTop && windowTop < section3) {
        this.activeTab = 'tab2'
      } else if (section3 <= windowTop && windowTop < section4) {
        this.activeTab = 'tab3'
      } else if (section4 <= windowTop) {
        this.activeTab = 'tab4'
      }
    },
    onClickStoreTab(sectionId) {
      let section = document.getElementById(sectionId).offsetTop - 40 - 115;
      if (this.mode == 'modal') {
        document.getElementById("store-content").scrollTo(0, section);
      } else {
        window.scrollTo(0, section);
      }
    },
    beginTransaction(product) {

      this.$modal.hide("suggest-product-modal");
      // check if product type is coin package
      if (product.type == 'c') {
        this.purchaseStart({
          transactionId: this.$route.query.transactionId,
          qty: 1,
          productId: product.id
        }).then((response) => {
          // console.log('webkit: ' + window.webkit);
          // console.log('appInterface: ' + window.appInterface);
          // window.webkit.messageHandlers.payment.postMessage({"transactionId":this.$route.query.transactionId,"productId":product.sku})
          // window.appInterface.payment.postMessage({transactionId:this.$route.query.transactionId,productId:product.sku})
          if (window.webkit != undefined) {
            try {
              window.webkit.messageHandlers.receiveMessageFromJS.postMessage(
                  '{"type":"payment", "merchantId":"406", "transactionId":"' + this.$route.query.transactionId + '","productId":"' +
                  product.sku + '"}')
              console.log("webkit Var");
            } catch (e) {
              this.$refs.resultModal.message = response.message;
              this.$refs.resultModal.success = false
              this.$refs.resultModal.show()
            }
          } else if (window.appInterface != undefined) {
            try {
              window.appInterface.postMessage('{"type":"payment", "merchantId":"406", "transactionId":"' + this.$route.query
                  .transactionId + '","productId":"' + product.sku + '"}')
              console.log("appInterface Var");

            } catch (e) {
              this.$refs.resultModal.message = response.message;
              this.$refs.resultModal.success = false
              this.$refs.resultModal.show()
            }
          } else {
            this.$refs.resultModal.message = "Üzgünüz, bir sorun oluştu. Lütfen daha sonra tekrar deneyin.";
            this.$refs.resultModal.success = false
            this.$refs.resultModal.show()
          }

        }).catch((error) => {
          this.$refs.resultModal.message = error.response.data.message;
          this.$refs.resultModal.success = false
          this.$refs.resultModal.show()
        })
      } else {
        if (this.player.coins < product.price) {

          // gets the coin package and finds next coin bundle thats price higher or equal than the price of the product user tries to buy
          const coinPackage = this.productGroups.find((g) => g.id == 1);
          this.suggestedProduct = coinPackage.products.find((p) => p.rewardInfo.coins >= product.price);
          this.$modal.show("suggest-product-modal");

        } else {
          this.purchaseProduct({
            id: product.id
          }).then((response) => {
            this.$emit('purchase', true);
            this.$refs.resultModal.message = response.message;
            this.$refs.resultModal.product = response.data.result.set.product;
            this.$refs.resultModal.success = true
            this.$refs.resultModal.show();
          }).catch((error) => {
            this.$emit('purchase', false);
            this.$refs.resultModal.message = error.response.data.message;
            this.$refs.resultModal.success = false
            this.$refs.resultModal.show()
          })
        }
      }
    },
    intervalDateToString(dateInt) {
      const explode = [...dateInt.match('(P|PT)([1-9]|[1-9][0-9])(W|D|M|H)')];

      if(explode[1] == 'PT' && explode[3] == 'M'){
        return explode[2]+' Dakika';
      }else if(explode[1] == 'PT' && explode[3] == 'H'){
        return explode[2]+' Saat';
      }else if(explode[1] == 'P' && explode[3] == 'D'){
        if(explode[2] == 1){
          return '24 Saat';
        }else{
          return explode[2]+' Saat';
        }
      }else if(explode[1] == 'P' && explode[3] == 'W'){
        return explode[2]+' Hafta';
      }else if(explode[1] == 'P' && explode[3] == 'M'){
        return explode[2]+' Ay';
      }else{
        console.log(dateInt);
      }
    }
  },

}
</script>

<style>

</style>