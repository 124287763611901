<template>
  <modal
      name="result-modal"
      classes="modal"
      :minWidth="200"
      :minHeight="400"
      :maxWidth="480"
      :scrollable="true"
      :adaptive="true"
      :reset="true"
      width="90%"
      height="auto">

    <div class="flex flex-col items-center">
      <h1 class="font-semibold text-center mt-8">{{ title }}</h1>
      <div class="result-item flex flex-col items-center" v-if="this.success && this.product !== null">
        <div class="result-item__img" v-if="product.type == 'c'">
          <img :src="product.image" alt="title">
          <span class="text-stroke"><small>x</small>{{ product.rewardInfo.coins }}</span>
        </div>
        <div class="result-item__img" v-if="product.type == 't'">
          <img :src="product.image" alt="title">
          <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.ticket }}</span>
        </div>
        <div class="result-item__img" v-if="product.type == 'o'">
          <img :src="require(`@/assets/store/ticket-immortal.svg`)" alt="">
          <span class="text-stroke">
                <small>+</small>
                <template v-if="product.rewardInfo.powerups.immortality.validUntil == '+PT1H'">
                  1 Saat
                </template>
                <template v-else-if="product.rewardInfo.powerups.immortality.validUntil == '+PT3H'">
                  3 Saat
                </template>
              </span>
        </div>
        <div class="result-item__img" v-if="product.type == 'p'">
          <template v-if="product.rewardInfo.powerups.rewind > 0">
            <img :src="product.image" :alt="product.title">
            <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
          </template>
          <template v-if="product.rewardInfo.powerups.open > 0">
            <img :src="product.image" :alt="product.title">
            <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
          </template>
          <template v-if="product.rewardInfo.powerups.hint > 0">
          <img :src="product.image" :alt="product.hint">
          <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.hint }}</span>
        </template>
        </div>
        <div class="result-item__img result-item__img--chest" v-if="product.type == 'm'">
          <template v-if="product.rewardInfo.powerups.immortality.isActive">
            <img class="mb-2" :src="product.image" :alt="product.title">
            <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.rewind > 0">
              <img :src="require(`@/assets/store/rewind.svg`)" class="mr-2" alt="">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
            </div>
            <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.open > 0">
              <img :src="require(`@/assets/store/open.svg`)" class="mr-2" alt="">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
            </div>
            <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.hint > 0">
              <img :src="require(`@/assets/store/hint.svg`)" class="mr-2" alt="">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.hint }}</span>
            </div>
            <div class="store-item__chest-item flex items-center">
              <img :src="require(`@/assets/store/ticket-immortal.svg`)" class="mr-2" alt="">
              <span class="text-stroke">
                    <small>+</small>
                    <template v-if="product.rewardInfo.powerups.immortality.validUntil == '+PT3H'">
                      3 Saat
                    </template>
                    <template v-else-if="product.rewardInfo.powerups.immortality.validUntil == '+PT24H'">
                      24 Saat
                    </template>
                  </span>
            </div>
          </template>
          <template v-else>
            <img class="mb-2" :src="product.image" :alt="product.title">
            <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.rewind > 0">
              <img :src="require(`@/assets/store/rewind.svg`)" class="mr-2" alt="">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
            </div>
            <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.open > 0">
              <img :src="require(`@/assets/store/open.svg`)" class="mr-2" alt="">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
            </div>
            <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.hint > 0">
              <img :src="require(`@/assets/store/hint.svg`)" class="mr-2" alt="">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.hint }}</span>
            </div>
          </template>
        </div>
      </div>
      <div class="result-item-error mb-4 mt-4" v-if="this.success == false">
        <img src="@/assets/icon-sad.svg">
        <p>{{message}}</p>
      </div>
      <div v-if="success && product !== null && product.type == 't'" class="flex justify-center my-4 w-3/4">
        <button class="btn btn--primary w-4/5" @click.prevent="onClickCreateGame">ŞİMDİ OYNA</button>
      </div>
      <button v-else :class="['btn self-center mb-5', this.success ? 'btn--primary' : 'btn--secondary']"
              style="padding: 8px 25px;" @click="$modal.hide('result-modal')">{{ button }}
      </button>
    </div>

  </modal>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  components: {},
  data() {
    return {
      success: true,
      message: null,
      product: null
    }
  },
  computed: {
    title() {
      return this.success ? 'SATIN ALINDI!' : 'BAŞARISIZ ÖDEME!'
    },
    text() {
      if (this.message) return this._.clone(this.message);
      return this.success ? 'İşleminiz başarıyla gerçekleştirildi.' : 'İşlem gerçekleştirilirken hata alındı. Lütfen bir süre bekleyip tekrar deneyin.'
    },
    button() {
      return this.success ? 'TAMAM' : 'KAPAT'
    }
  },
  methods: {
    ...mapActions(["createGame"]),
    show() {
      this.$modal.show("result-modal");
    },
    hide() {
      this.$modal.hide("result-modal");
    },
    onClickCreateGame() {
      if(this.$route.name == "game") {
        this.$modal.hide("result-modal");
        this.$modal.hide("store-modal");
      }
      else {
        this.createGame({gameType: '1'}).then(() => {
          this.$router.push({name: "game", params: {id: '1'}, query: this.$route.query})
        })
      }
    }
  }
}
</script>