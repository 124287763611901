import request from './request'

const api = {
  getPlayer(playerId) {
    return request.get(`/player/${playerId}`)
  },

  patchPlayerAlias(payload) {
    return request.patch(`/player/${payload.id}/alias/${payload.alias}`)
  },

  patchPlayerAvatar(payload) {
    return request.patch(`/player/${payload.id}/avatar/${payload.avatar.fileName}`)
  },

  retrieveGame(gameId) {
    return request.get(`/gameplay/${gameId}`)
  },

  createGame(type) {
    return request.get(`/gameplay/load/${type}`)
  },

  updateGame(payload) {
    return request.put(`/gameplay/${payload.id}`, payload.data)
  },

  finalizeGame(payload) {
    return request.post(`/gameplay/finalize`, payload)
  },

  usePowerup(payload) {
    return request.delete(`/gameplay/${payload.id}/power-up/${payload.key}`)
  },

  getProductsByGroup() {
    return request.get(`/products/by-group`)
  },

  getGlobalStanding(date) {
    return request.get(`/standing/of/${date}`)
  },

  getPlayerStanding(payload) {
    return request.get(`/standing/of/${payload.player}/${payload.date}`)
  },

  getTopTen(date) {
    return request.get(`/leaderboard/top-ten/${date}`)
  },

  getLeaderboard(payload) {
    return request.get(`/leaderboard/all/${payload.date}`, { params: payload.params })
  },

  buyProduct(productId) {
    return request.post(`purchase/product/${productId}`)
  },

  startPurchase(payload) {
    return request.post(`/purchase/start`, payload)
  },

  getAvatars() {
    return request.get('/avatars')
  },

  getContent(contentId) {
    return request.get(`/static-content/${contentId}`)
  },

  getPaycellReward(next) {
    return request.get(`/player/reward-history?`+ next)
  },

  newTicket(payload) {
    return request.post(`/ticket/newTicket`, payload);
  },

  uploadTicketImage(payload) {
    return request.post('ticket/upload', payload, {headers: { "Content-Type": "multipart/form-data" }},
    );
  }
}
export default api