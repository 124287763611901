<template>
  <div class="settings">
    <div class="container mx-auto max-w-sm">
      <div class="flex flex-col items-center">
        <h1 class="mt-8 font-semibold mb-8">AYARLAR</h1>
        <div class="player-avatar mb-6">
          <div class="player-avatar__canvas">
            <img :src="player.avatarUrl" alt="">
          </div>
          <button class="btn btn--rounded btn--xs btn--primary" @click="onClickAvatarButton"><img src="@/assets/icon-image.svg" alt=""></button>
          <modal 
            name="avatar-modal" 
            classes="modal"
            :minWidth="200"
            :minHeight="400"
            :maxWidth="480"
            :scrollable="true"
            :adaptive="true"
            :reset="true"
            width="90%"
            height="auto">
            
            <div class="flex justify-end relative">
              <button @click="$modal.hide('avatar-modal')" class="absolute right-0 mr-4 mt-4">
                <img src="@/assets/times-black.svg" alt="">
              </button>
            </div>
            <div class="flex flex-col h-full">
              <h1 class="font-semibold text-center py-4 flex-shrink-0">AVATARINI SEÇ</h1>
              <div class="flex flex-col items-center overflow-scroll">
                <div class="avatar-list my-4 grid grid-cols-2 gap-4 w-full">
                  <div class="avatar-list__item justify-self-center" v-for="(avatar,i) in avatars" :key="`avatar-${i}`">
                    <button><img :src="avatar.path" alt="" @click="onClickAvatarSelectButton(avatar)"></button>
                  </div>
                </div>
              </div>
            </div>
          </modal>
        </div>
        <div class="player-alias mb-6">
          {{player.alias}}
          <!--button class="player-alias__button" @click="onClickAliasButton"><img src="@/assets/icon-edit.svg" alt=""></button-->
          <modal 
            name="alias-modal" 
            classes="modal"
            :minWidth="200"
            :minHeight="400"
            :maxWidth="480"
            :scrollable="true"
            :adaptive="true"
            :reset="true"
            width="90%"
            @opened="onOpenedAliasModal"
            @closed="onClosedAliasModal"
            height="auto">
            
            <div class="flex justify-end relative">
              <button @click="$modal.hide('alias-modal')" class="absolute right-0 mr-4 mt-4">
                <img src="@/assets/times-black.svg" alt="">
              </button>
            </div>
            <h1 class="font-semibold text-center py-4 flex-shrink-0">İSMİNİ DEĞİŞTİR</h1>
            <form class="flex flex-col items-center mb-8" @submit.prevent="onClickSaveAliasButton">
              <input ref="alias" type="text" class="form-input" v-model="alias" maxlength="16">
            </form>
            <div class="flex justify-center items-center mb-4">
              <button class="btn btn--sm btn--secondary" @click="$modal.hide('alias-modal')">KAPAT</button>
              <button :disabled="!alias" :class="{'btn--disabled': !alias}" class="btn btn--sm btn--primary" @click="onClickSaveAliasButton">KAYDET</button>
            </div>
          </modal>
        </div>
        <div class="player-rewards w-full">
          <h2 class="text-center mt-6 mb-4">OYNAKAZAN Ödüllerim</h2>
          <table class="table-auto player-rewards__table w-full">
            <colgroup>
              <col style="width: 25%">
              <col style="width: 50%">
            </colgroup>
            <thead>
            <tr>
              <th>Tarih</th>
              <th>Ödül</th>
            </tr>
            </thead>
            <tbody v-if="oynakazanRewards.length" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
            <tr v-for="(oreward, key) in oynakazanRewards" :key="key">
              <td>
                {{ oreward.rewardDate }}
              </td>
              <td>
                {{ oreward.powerups.paycellOffer }}
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="3">Henüz kazandığınız bir ödül bulunmuyor</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="player-rewards w-full">
          <h2 class="text-center mt-6 mb-4">LİG Ödüllerim</h2>
          <table class="table-auto player-rewards__table w-full">
            <colgroup>
              <col style="width: 25%">
              <col style="width: 25%">
              <col style="width: 50%">
            </colgroup>
            <thead>
            <tr>
              <th>Tarih</th>
              <th>Sıralama</th>
              <th>Ödül</th>
            </tr>
            </thead>
            <tbody v-if="rewards.length" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
            <tr v-for="(reward, key) in rewards" :key="key">
              <td>
                {{ reward.rewardDate }}
              </td>
              <td align="center">
                {{ reward.standing }}
              </td>
              <td>
                {{ reward.powerups.paycellOffer }}
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="3">Henüz kazandığınız bir ödül bulunmuyor</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
  data(){
    return {
      alias: null,
      rewards: [],
      oynakazanRewards: [],
      nextUrl: '',
      startScroll: 0
    }
  },
  computed: {
    ...mapState(["player", "avatars"])
  },
  watch: {
    "player.alias": function(){
      this.alias = this.player.alias;
    }
  },
  created(){
    this.alias = this.player.alias;
    this.getAvatars()
    this.paycellReward('')
  },
  methods: {
    ...mapActions(["updatePlayerAlias", "updatePlayerAvatar", "getAvatars", "getPaycellReward"]),
    onClickAvatarButton(){
      this.$modal.show("avatar-modal");
    },
    onClickAvatarSelectButton(avatar) {
      this.updatePlayerAvatar({id: this.player.id, avatar: avatar}).then(()=>{
        this.$modal.hide("avatar-modal");
      })
    },
    onClickAliasButton() {
      this.$modal.show("alias-modal");
    },
    onOpenedAliasModal() {
      this.$refs.alias.focus();
    },
    onClosedAliasModal() {
      this.alias = this.player.alias;
    },
    onClickSaveAliasButton(){
      if (this.alias) {
        this.updatePlayerAlias({
          id: this.player.id,
          alias: this.alias
        }).then(() => {
          this.$modal.hide("alias-modal");
        }).catch(error => {
          this.$toast.error(error.response.data.message);
        })
      }
    },
    loadMore(){
      if(this.startScroll == 1){ this.paycellReward(this.nextUrl) }
    },
    paycellReward(nextlink) {
      this.getPaycellReward(nextlink).then((data)=>{
        if(data.set.paycell) { this.rewards = data.set.paycell }
        if(data.set.oynakazan) { this.oynakazanRewards = data.set.oynakazan }
        this.nextUrl = data.pagination.nextLink
        this.startScroll = 1
      })
    }
  }
}
</script>

<style>

</style>