<template>
  <div>
    <modal 
      name="store-modal" 
      classes="modal"
      :minWidth="200"
      :minHeight="400"
      :maxWidth="480"
      :scrollable="true"
      :adaptive="true"
      :reset="true"
      width="100%"
      @opened="onOpenedStoreModal"
      height="auto">
      
      <div class="flex justify-end relative">
        <button @click="$modal.hide('store-modal')" class="absolute right-0 mr-4 mt-4">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>
      
      <store-content :mode="'modal'" ref="storeContent"></store-content>
    </modal>
  </div>
</template>

<script>
import StoreContent from "@/views/components/StoreContent"
export default {
  components: {
    StoreContent
  },
  methods: {
    onOpenedStoreModal() {
      this.$nextTick(() => {
        this.$refs.storeContent.checkActiveTab();
      })
    },
    show() {
      this.$modal.show("store-modal");
    },
    hide() {
      this.$modal.hide("store-modal");
    }
  }
}
</script>

<style>

</style>