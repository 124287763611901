<template>
  <div class="home m-9">
    <div class="flex flex-col items-center justify-center p-4" v-html="content.content" style="padding-left: 3rem">
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  components: {},
  data() {
    return {
      content: ""
    }
  },
  methods: {
    ...mapActions(["getContent"])
  },
  created() {
    this.getContent(4).then((response) => {
      this.content = response;
    })
  },
}
</script>
<style scoped>
tr:nth-child(4n+1), tr:nth-child(4n-1) {
  background-color: #80808069;
}
</style>