<template>
  <modal 
    name="settings-modal" 
    classes="modal"
    :minWidth="200"
    :minHeight="600"
    :maxWidth="480"
    :scrollable="true"
    :adaptive="true"
    :reset="true"
    width="90%"
    height="auto">
    
    <div class="flex justify-end relative">
      <button @click="$modal.hide('settings-modal')" class="absolute right-0 mr-4 mt-4">
        <img src="@/assets/times-black.svg" alt="">
      </button>
    </div>
    <div class="flex flex-col pb-8 items-center">
      <h1 class="mt-3 font-semibold mb-8">AYARLAR</h1>
      <div class="player-avatar mb-6">
        <div class="player-avatar__canvas">
          <img :src="player.avatarUrl" alt="">
        </div>
        <button class="btn btn--rounded btn--xs btn--primary" @click="onClickAvatarButton"><img src="@/assets/icon-image.svg" alt=""></button>
        <modal 
          name="avatar-modal" 
          classes="modal"
          :minWidth="200"
          :minHeight="400"
          :maxWidth="480"
          :scrollable="true"
          :adaptive="true"
          :reset="true"
          width="90%"
          height="auto">
          
          <div class="flex justify-end relative">
            <button @click="$modal.hide('avatar-modal')" class="absolute right-0 mr-4 mt-4">
              <img src="@/assets/times-black.svg" alt="">
            </button>
          </div>
          <div class="flex flex-col h-full">
            <h1 class="font-semibold text-center py-4 flex-shrink-0">AVATARINI SEÇ</h1>
            <div class="flex flex-col items-center overflow-scroll">
              <div class="avatar-list my-4 grid grid-cols-2 gap-4 w-full">
                <div class="avatar-list__item justify-self-center" v-for="(avatar,i) in avatars" :key="`avatar-${i}`">
                  <button><img :src="avatar.path" alt="" @click="onClickAvatarSelectButton(avatar)"></button>
                </div>
              </div>
            </div>
          </div>
        </modal>
      </div>
      <div class="player-alias">
        {{player.alias}}
        <button class="player-alias__button" @click="onClickAliasButton"><img src="@/assets/icon-edit.svg" alt=""></button>
        <modal 
          name="alias-modal" 
          classes="modal"
          :minWidth="200"
          :minHeight="400"
          :maxWidth="480"
          :scrollable="true"
          :adaptive="true"
          :reset="true"
          width="90%"
          @opened="onOpenedAliasModal"
          @closed="onClosedAliasModal"
          height="auto">
          
          <div class="flex justify-end relative">
            <button @click="$modal.hide('alias-modal')" class="absolute right-0 mr-4 mt-4">
              <img src="@/assets/times-black.svg" alt="">
            </button>
          </div>
          <h1 class="font-semibold text-center py-4 flex-shrink-0">İSMİNİ DEĞİŞTİR</h1>
          <form class="flex flex-col items-center mb-8" @submit.prevent="onClickSaveAliasButton">
            <input ref="alias" type="text" class="form-input" v-model="alias" maxlength="16">
          </form>
          <div class="flex justify-center items-center mb-4">
            <button class="btn btn--sm btn--secondary" @click="$modal.hide('alias-modal')">KAPAT</button>
            <button :disabled="alias == ''" :class="{'btn--disabled': alias == ''}" class="btn btn--sm btn--primary" @click="onClickSaveAliasButton">KAYDET</button>
          </div>
        </modal>
      </div>
    </div>
  </modal>
  
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
  data(){
    return {
      alias: null
    }
  },
  computed: {
    ...mapState(["player", "avatars"])
  },
  watch: {
    "player.alias": function(){
      this.alias = this.player.alias;
    }
  },
  created(){
    this.alias = this.player.alias;
    this.getAvatars()
  },
  methods: {
    ...mapActions(["updatePlayerAlias", "updatePlayerAvatar", "getAvatars"]),
    show() {
      this.$modal.show("settings-modal");
    },
    hide() {
      this.$modal.hide("settings-modal")
    },
    onClickAvatarButton(){
      this.$modal.show("avatar-modal");
    },
    onClickAvatarSelectButton(avatar) {
      this.updatePlayerAvatar({id: this.player.id, avatar: avatar}).then(()=>{
        this.$modal.hide("avatar-modal");
      })
    },
    onClickAliasButton() {
      this.$modal.show("alias-modal");
    },
    onOpenedAliasModal() {
      this.$refs.alias.focus();
    },
    onClosedAliasModal() {
      this.alias = this.player.alias;
    },
    onClickSaveAliasButton() {
      if (this.alias) {
        this.updatePlayerAlias({
          id: this.player.id,
          alias: this.alias
        }).then(() => {
          this.$modal.hide("alias-modal");
        }).catch(error => {
          this.$toast.error(error.response.data.message);
        })
      }
    }
  }
}
</script>



<style>

</style>