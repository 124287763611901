import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading)
Vue.component('loading', Loading)

import { extend, localize } from 'vee-validate';
import { required, email, alpha } from 'vee-validate/dist/rules';
import tr from 'vee-validate/dist/locale/tr.json';

// No message specified.
extend('email', email);
extend('required', required);
extend('alpha', alpha);

localize('tr', tr);

// name is optional
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast);

// Vue Modal
import VModal from 'vue-js-modal'
Vue.use(VModal, {dialog: true})

// Tailwind
import '@/assets/css/main.css'

import './assets/scss/main.scss'

Vue.config.productionTip = false

store.dispatch('init').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})