import Vue from "vue"
import Vuex from "vuex"
import _ from "lodash"
import authApi from "@/api/auth"
import appApi from "@/api/app"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    inMaintenance: false,
    isLoading: false,
    sessionToken: null,
    authenticated: false,
    gamePlay: null,
    productGroups: [],
    avatars: [],
    notifications: [],
    player: {
      id: null,
      alias: "tsemmui",
      avatarUrl: "/cdn/avatar/tsemmui-avatar.png",
      powerups: {
        rewind: 2,
        open: 18,
        change: 0,
        ticket: 10,
        immortality: {
          isActive: true,
          validUntil: "2019-08-24T14:15:22Z",
        },
      },
      coins: 0,
      playerStanding: {
        currentRank: null,
        score: null
      },
      appSettings: {
        locale: "tr-tr",
        gameEffects: true,
        gameMusic: true,
        cdnUrl: "https://paycell.cdn.adam-asmacam.com",
        maxMove: 7,
        notificationEnabled: true,
        notificationSettings: {
          leaderboardNotifications: "push",
          gameResultNotifications: "push",
          marketingNotifications: "push",
          paymentNotifications: "email",
          systemNotifications: "email",
          legalNotifications: "email",
        },
        theme: "default",
      },
      isImmortal: false,
    },
    leaderboard: [],
    leaderboardPagination: null
  },
  getters: {
    isAuthenticated(state) {
      return state.authenticated
    },
    isNotificationEnabled(state) {
      return state.player.appSettings.notificationEnabled
    },
    checkTicket(state) {
      if (state.player.powerups.immortality.validUntil) {
        var date = new Date();
        var dateTwo = new Date(state.player.powerups.immortality.validUntil);
        if(date < dateTwo)
        {
          return true
        }
      }
      return state.player.powerups.ticket > 0
    },
    hasGamePlay(state) {
      return null != state.gamePlay
    },
    getRandomWord(state) {
      let index = _.random(0, state.words.length - 1)
      return state.words[index]
    },
    atMaintenanceMode(state) {
      return state.atMaintenance
    },
  },
  mutations: {
    SET_SESSION_TOKEN(state, payload) {
      state.sessionToken = payload
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload
    },
    SET_IS_MAINTENANCE(state, payload) {
      state.inMaintenance = payload
    },
    SET_AUTHENTICATED(state, payload) {
      state.authenticated = payload
    },
    SET_APP_SETTINGS(state, payload) {
      state.player.appSettings = payload
    },
    SET_PLAYER_INFO(state, payload) {
      state.player = payload
    },
    UPDATE_PLAYER_INFO(state, payload) {
      var player = state.player

      player.id = payload.id
      player.alias = payload.alias
      player.avatarUrl = payload.avatarUrl
      player.powerups = payload.powerups
      player.coins = payload.coins
      player.isImmortal = payload.isImmortal
      player.isTestUser= payload.isTestUser
      player.playerStanding = payload.playerStanding

      state.player = player
    },
    UPDATE_PLAYER_ALIAS(state, payload) {
      state.player.alias = payload;
    },
    UPDATE_PLAYER_AVATAR(state, payload) {
      state.player.avatarUrl = payload;
    },
    UPDATE_PLAYER_POWERUP(state, payload) {
      state.player.powerups = payload
    },
    ADD_COINS(state, coins) {
      state.player.coins += coins
    },
    REMOVE_COINS(state, coins) {
      state.player.coins -= coins
    },
    ADD_TICKETS(state, tickets) {
      state.player.powerups.ticket += tickets
    },
    REMOVE_TICKET(state) {
      state.player.powerups.ticket--
    },
    SET_GAMEPLAY(state, payload) {
      state.gamePlay = payload
    },
    SET_PRODUCT_GROUPS(state, payload) {
      state.productGroups = payload
    },
    EMPTY_LEADERBOARD(state) {
      state.leaderboard = [];
    },
    SET_LEADERBOARD(state, payload) {
      if(payload.length == 11){
        state.leaderboard = payload
      } else {
        state.leaderboard = [...state.leaderboard.slice(0, 11), ...payload]
      }
    },
    SET_LEADERBOARD_PAGINATION(state, payload) {
      state.leaderboardPagination = payload;
    },
    SET_AVATARS(state, payload) {
      state.avatars = payload
    },
    UPLOAD_TICKET_IMAGE() {},
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },
    READ_NOTIFICATION(state, payload) {
      const index = state.notifications.indexOf(payload);
      state.notifications.splice(index, 1);
    },
    UPDATE_PLAYER_NOTIFICATION_STATUS(state, payload) {
      state.player.appSettings.notificationEnabled = payload
    }
  },
  actions: {
    openLoader({ commit }) {
      commit("SET_IS_LOADING", true)
    },
    paycellLogin({commit})
    {
      sessionStorage.removeItem("oynaKazanWaithingCreate")
      sessionStorage.removeItem("oynaKazanUnplayedGames")

      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      let playerId = urlParams.get('externalId')
      return new Promise((resolve, reject) => {
        commit('SET_SESSION_TOKEN', null);
        return new authApi.paycellLogin(playerId).then((response) => {
          commit('SET_AUTHENTICATED', true)
          commit('SET_SESSION_TOKEN', response.data.sessionToken)
          commit('UPDATE_PLAYER_INFO', response.data.result.set)
          localStorage.setItem('playerId', response.data.result.set.id);

          let oynaKazanTransaction = response.data.result.set.oynaKazanTransaction
          if(typeof(oynaKazanTransaction) != "undefined"){
            let oynaKazanGame = response.data.result.set.oynaKazanGame
            let foundGames = []
            let waithingCreateGame = []

            oynaKazanTransaction.forEach((element) => {
              if(element.status == "c" && typeof(oynaKazanGame) != "undefined" && element.instructions != null) {
                element.instructions.gameId.forEach((game)=>{
                  var foundGame = oynaKazanGame.find(element => element.id === game)
                  if(typeof foundGame != 'undefined') {
                    foundGames.push(foundGame)
                  }
                })
              }
              if(element.status == "c" && element.instructions == null){
                waithingCreateGame.push(element.product)
              }
              if(element.status == "w"){
                if(element.purchasedAt.timestamp > Math.round((new Date()).getTime() / 1000)-300){
                  setTimeout(()=>{this.oynaKazanError()},1000)
                }
              }
            })
            sessionStorage.setItem('oynaKazanWaithingCreate',JSON.stringify(waithingCreateGame))
            sessionStorage.setItem('oynaKazanUnplayedGames',JSON.stringify(foundGames))
          }

          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })


    },
    init({ state, commit }) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      if(urlParams.get('externalId') && urlParams.get('transactionId')) {
        let environment = process.env.NODE_ENV == 'development' ? 'dev' : 'prod'
        return new Promise((resolve, reject) => {
          return new authApi.init(environment).then((response) => {
            commit('SET_APP_SETTINGS', response.data.result.set.appInfo.defaultSettings)
            if(!state.authenticated) {
              this.dispatch('paycellLogin')
            }

            resolve(response)

          }).catch(error => {
            reject(error)
          })
        })
      }
    },
    getPlayerInfo({ commit }, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.getPlayer(payload.id).then((response) => {
          commit("UPDATE_PLAYER_INFO", response.data.result.set)
          commit("SET_IS_LOADING", false)
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    updatePlayerAlias({commit}, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.patchPlayerAlias(payload).then((response) => {
          commit("UPDATE_PLAYER_ALIAS", payload.alias);
          commit("SET_IS_LOADING", false)
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    updatePlayerAvatar({commit}, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.patchPlayerAvatar(payload).then((response) => {
          commit("UPDATE_PLAYER_AVATAR", payload.avatar.path);
          commit("SET_IS_LOADING", false)
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    createGame({ commit, state }, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.createGame(payload.gameType).then((response) => {
          if(null != response.data.result.set) {
            commit("SET_GAMEPLAY", response.data.result.set)
            commit("UPDATE_PLAYER_INFO", response.data.result.set.player)
            commit("SET_IS_LOADING", false)

            localStorage.setItem('atGame', true)
            if (state.gamePlay.difficulty == 0) {
              localStorage.setItem('trainingGameId', state.gamePlay.id)
            }

            if (state.gamePlay.difficulty == 1) {
              localStorage.setItem('leagueGameId', state.gamePlay.id)
            }

            resolve(response)
        }
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    async updateGame({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        return new appApi.updateGame(payload).then((response) => {
          commit("SET_GAMEPLAY", response.data.result.set)
          commit("UPDATE_PLAYER_INFO", response.data.result.set.player)

          localStorage.setItem('atGame', true)
          if(state.gamePlay.difficulty == 0)  {
            localStorage.setItem('trainingGameId', state.gamePlay.id)
          }

          if(state.gamePlay.difficulty == 1)  {
            localStorage.setItem('leagueGameId', state.gamePlay.id)
          }

          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    finalizeGame({commit}, payload) {
      commit("SET_IS_LOADING", true)

      return new Promise((resolve, reject) => {
        return new appApi.finalizeGame(payload).then((response) => {
          commit("SET_GAMEPLAY", null)
          commit("UPDATE_PLAYER_INFO", response.data.result.set.player)
          commit("SET_IS_LOADING", false)

          if (response.data.result.set.difficulty == 0) {
            let oynaKazan = JSON.parse(sessionStorage.getItem('oynaKazanUnplayedGames'))
            oynaKazan.shift();
            sessionStorage.setItem('oynaKazanUnplayedGames',JSON.stringify(oynaKazan))
          }

          localStorage.setItem('atGame', false)
          localStorage.setItem('trainingGameId', null)
          localStorage.setItem('leagueGameId', null)

          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    usePowerup({ commit, state }, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.usePowerup(payload).then((response) => {
          commit("UPDATE_PLAYER_POWERUP", response.data.result.set)

          localStorage.setItem('atGame', true)
          if(state.gamePlay.difficulty == 0)  {
            localStorage.setItem('trainingGameId', state.gamePlay.id)
          }

          if(state.gamePlay.difficulty == 1)  {
            localStorage.setItem('leagueGameId', state.gamePlay.id)
          }

          commit("SET_IS_LOADING", false)
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    async retrieveProductGroups({commit}) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.getProductsByGroup().then((response) => {
          commit("SET_PRODUCT_GROUPS", response.data.result.set)
          commit("SET_IS_LOADING", false)
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    purchaseStart({commit}, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.startPurchase(payload).then((response) => {
          commit("SET_IS_LOADING", false)
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    purchaseProduct({commit}, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.buyProduct(payload.id).then((response) => {
          commit("UPDATE_PLAYER_INFO", response.data.result.set.player)
          commit("SET_IS_LOADING", false)
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    setSessionToken({ commit }, payload) {
      commit("SET_SESSION_TOKEN", payload)
    },
    setMaintenanceMode({ commit }, payload) {
      commit("SET_IS_MAINTENANCE", payload)
    },
    getWord({ state, commit, dispatch }, payload) {
      if(null == state.gamePlay) {
        if(localStorage.getItem("atGame") != 'false') {
          let gameType = payload.gameType == 1 ? 'leagueGameId' : 'trainingGameId'
          if(payload.gameId>1){
            return new Promise((resolve, reject) => {
              return new appApi.retrieveGame(payload.gameId).then((response) => {
                commit("SET_GAMEPLAY", response.data.result.set)
                resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary, moves: state.gamePlay.move})
              }).catch((err) => {
                reject(err)
              })
            })
          }else if(localStorage.getItem(gameType)) {
            return new Promise((resolve, reject) => {
              return new appApi.retrieveGame(localStorage.getItem(gameType)).then((response) => {
                commit("SET_GAMEPLAY", response.data.result.set)
                resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary, moves: state.gamePlay.move})
              }).catch((err) => {
                reject(err)
              })
            })
          } 
          else {
            return new Promise((resolve, reject) => {
              dispatch('createGame', payload).then(() => {
                resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary})
              }).catch((err) => {
                  reject(err)
              })
            })
          } 
        } else {
          return new Promise((resolve, reject) => {
            dispatch('createGame', payload).then(() => {
              resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary})
            }).catch((err) => {
              reject(err)
            })
          })
        }
      }
      return Promise.resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary})
    },
    getTopTen({commit}) {
      commit("SET_IS_LOADING", true)

      var today = new Date();
      var day = String(today.getDate()).padStart(2, '0');
      var month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var year = today.getFullYear();

      let date = `${year}-${month}-${day}`

      return new Promise((resolve, reject) => {
        return new appApi.getTopTen(date).then((response) => {
          commit("SET_LEADERBOARD", response.data.result.set)
          commit("SET_IS_LOADING", false)
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    getLeaderboard({commit}, payload) {
      commit("SET_IS_LOADING", true)

      var today = new Date();
      var day = String(today.getDate()).padStart(2, '0');
      var month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var year = today.getFullYear();

      let date = `${year}-${month}-${day}`

      return new Promise((resolve, reject) => {
        return new appApi.getLeaderboard({date: date, params: payload.params}).then((response) => {
          commit("SET_LEADERBOARD", response.data.result.set)
          commit("SET_LEADERBOARD_PAGINATION", response.data.result.pagination);
          commit("SET_IS_LOADING", false)
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    refreshLeaderboard({dispatch}, payload) {
      return dispatch("emptyLeaderboard").then(() => {
        return dispatch("getTopTen").then(() => {
          if(payload.params.limit > 0) {
            return dispatch("getLeaderboard", payload)
          }
        });
      });
    },
    emptyLeaderboard({commit}) {
      commit("EMPTY_LEADERBOARD");
    },
    getGlobalStanding({commit}, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.getGlobalStanding(payload.date).then((response) => {
          commit("SET_IS_LOADING", false)
          resolve(response.data.result.set)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    buyCoins({ commit }, coins) {
      commit("ADD_COINS", coins)
    },
    buyTickets({ commit, state }, product) {
      if (state.player.coins >= product.value) {
        commit("ADD_TICKETS", product.quantity)
        commit("REMOVE_COINS", product.value)
      }
    },
    spendTicket({ commit }) {
      commit("REMOVE_TICKET")
    },
    getAvatars( {commit} ) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.getAvatars().then((response) => {
          commit("SET_IS_LOADING", false)
          commit("SET_AVATARS", response.data.result.set)
          resolve(response)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    getContent({ commit }, contentId) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.getContent(contentId).then((response) => {
          commit("SET_IS_LOADING", false)
          resolve(response.data.result.set)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    createNewTicket({commit}, payload) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.newTicket(payload).then((response) => {
          commit("SET_IS_LOADING", false)
          resolve(response.data)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    getPaycellReward({ commit }, nextlink) {
      commit("SET_IS_LOADING", true)
      return new Promise((resolve, reject) => {
        return new appApi.getPaycellReward(nextlink).then((response) => {
          commit("SET_IS_LOADING", false)
          resolve(response.data.result)
        }).catch(error => {
          commit("SET_IS_LOADING", false)
          reject(error)
        })
      })
    },
    uploadTicketImage({commit}, payload) {
      commit("UPLOAD_TICKET_IMAGE");
      return new Promise((resolve, reject) => {
        return new appApi.uploadTicketImage(payload).then((response) => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getPlayerNotifications({commit}) {
      return new Promise((resolve, reject) => {
        return new appApi.getNotifications().then((response) => {
          commit("SET_NOTIFICATIONS", response.data.result.set);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    readPlayerNotification({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new appApi.patchNotification(payload).then((response) => {
          commit("READ_NOTIFICATION", payload);
          resolve(response);
        }).catch(error => {
          reject(error)
        })
      });
    },
    enablePlayerNotification({commit}) {
      return new Promise((resolve, reject) => {
        return new appApi.enableNotifications().then((response) => {
          commit("UPDATE_PLAYER_NOTIFICATION_STATUS", true);
          resolve(response);
        }).catch(error => {
          reject(error)
        })
      });
    },
    disablePlayerNotification({commit}) {
      return new Promise((resolve, reject) => {
        return new appApi.disableNotifications().then((response) => {
          commit("UPDATE_PLAYER_NOTIFICATION_STATUS", false);
          resolve(response);
        }).catch(error => {
          reject(error)
        })
      });
    }
  }
})
