<template>
  <div class="home">
    <loading :active.sync="this.$store.state.isLoading" :is-full-page="true" :lock-scroll="true"
             :backgroundColor="`#000000`" :color="`#FFB74F`" :blur="`7px`" :opacity="0.5" :width="64"
             :height="64"></loading>
    <div class="flex flex-col items-center justify-center">
      <div v-html="content.content" class="text-center mt-8 p-4"></div>
      <div v-if="!this.formStatus">
        <validation-observer ref="form">
      <form @submit.prevent="sendTicket">
        <div class="flex flex-col" v-if="!this.formStatus">
          <h2 class="text-center">İsim Soyisim</h2>
          <validation-provider name="İsim Soyisim" rules="required" v-slot="{errors}">
            <input type="text" class="form-special" required v-model="form.name"/>
            <div class="error-label">{{ errors[0] }}</div>
          </validation-provider>
          <h2 class="text-center">E-Posta</h2>
          <validation-provider name="E-Posta" rules="required|email" v-slot="{errors}">
            <input type="email" class="form-special" required v-model="form.email"/>
            <div class="error-label">{{ errors[0] }}</div>
          </validation-provider>
          <h2 class="text-center">Konu</h2>
          <input type="text" class="form-special" required v-model="form.subject"/>
          <h2 class="text-center">Mesaj</h2>
          <validation-provider name="Mesaj" rules="required" v-slot="{errors}">
            <textarea class="form-special" rows="8" v-model="form.message"></textarea>
            <div class="error-label">{{ errors[0] }}</div>
          </validation-provider>
          <!--
          <div class="w-full mt-4 px-6">
            <template>
              <uploader
                  v-model="fileList"
                  :url="remoteUrl"
                  title="Görsel"
                  :headers="addHeader"
                  :autoUpload="false"
                  @on-change="onChange"
                  @on-success="onSuccess"
                  @on-error="onError"
                  @on-delete="onError"
                  :limit="3"
              ></uploader>
            </template>
          </div>
          -->
          <button class="btn mt-5" :class="{'disabled': sending}" :disabled="sending">Gönder</button>
        </div>
      </form>
        </validation-observer>
        </div>
      <div class="flex flex-col" v-if="this.formStatus">
        <h1 class="text-success text-center">Mesajınız alınmıştır. En kısa sürede size dönüş yapacağız</h1>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions} from 'vuex'
// import Uploader from "vux-uploader-component";
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {
    // Uploader,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: ""
      },
      sending: false,
      remoteUrl: process.env.VUE_APP_API_URL + '/ticket/upload',
      fileList: [],
      addHeader:{'X-Api-Key':process.env.VUE_APP_API_KEY,'X-Session-Token':localStorage.sessionToken},
      postData: {
        inboxId: 4545,
        customerEmail: "",
        subject: "",
        message: ""
      },
      formStatus: false,
      content: ""
    }
  },
  methods: {
    ...mapActions(["getContent", "createNewTicket", "getPlayerInfo", "uploadTicketImage"]),
    sendTicket() {
      this.$refs.form.validate().then((isValid) => {
        if (!isValid) return;
        this.postData.customerEmail = this.form.email;
        this.postData.subject = "#AdamAsmaca-Paycell - " + this.form.subject;
        if (this.$store.state.authenticated) {
          this.postData.message += "Kullanıcı Id: " + localStorage.getItem('playerId');
        }
        this.postData.message += "<br>Mesaj: " + this.form.message;
        if (this.fileList.length > 0) {
          var items = ''
          this.fileList.forEach(function (item) {
            items += item.url + '<br><br>'
          })
          this.postData.message += "<br><br>Ekran Görüntüsü: " + items;
        }
        this.postData.message += "<br><br><br>Tarayıcı Detayları: " + navigator.userAgent;
        this.createNewTicket(this.postData).then(() => {
          this.formStatus = true
        });
      })
    },
    onChange(file) {
      let formData = new FormData();
      formData.append('file', file.blob);
      this.sending = true;
      this.uploadTicketImage(formData).then((response) => {
        this.sending = false;
        this.onSuccess(response, file);
      })
    },
    onSuccess(result, fileItem){
      fileItem.url = result.data.result.set.ObjectURL
    },
    onError(xhr,fileItem){
      console.log("error")
      console.log(fileItem)
    }
  },
  beforeDestroy() {
    window.deskcontactwidget = null;
    document.getElementById('deskWidgetMain').remove();
  },
  created() {
    this.getContent(2).then((response) => {
      this.content = response;
    })
  },
}
</script>