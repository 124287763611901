<template>
  <div class="layout-main vld-parent">
    <loading :active.sync="this.$store.state.isLoading" 
        :is-full-page="true"
        :lock-scroll="true" 
        :backgroundColor="`#000000`"
        :color="`#FFB74F`"
        :blur="`7px`"
        :opacity="0.5"
        :width="64"
        :height="64"
        ></loading>

    <div class="nav-wrapper">
      <header class="flex items-center">
        <div class="container mx-auto max-w-sm px-10 items-center" :class="[this.$store.state.authenticated ? 'grid grid-cols-3 gap-4' : '' ]">
          <div class="menu" @click="onClickMenu"  v-if="this.$store.state.authenticated">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="line" d="M10.7738 17.5925C18.0603 17.5925 27.9454 15.9485 34.8294 18.0904C40.3027 19.7934 45.5682 17.5925 50.7738 17.5925" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="line" d="M10.7738 30.1761C19.4824 30.1761 27.548 29.1805 36.2695 29.1805" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="line" d="M10.0382 41.8011C14.3513 41.7411 18.5165 41.0543 22.8647 41.0543C26.963 41.0543 31.2498 40.8058 35.3014 41.2479C38.5288 41.6002 42.048 41.3033 45.3209 41.3033C46.2827 41.3033 49.6176 41.4022 50.0382 40.8054" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="logo" :class="[this.$store.state.authenticated ? 'flex items-center' : '' ]">
            <router-link :to="{name: 'dashboard', query: this.$route.query}"><img :src="require('@/assets/logo.svg')" alt="" :class="[this.$store.state.authenticated ? '' : 'mx-auto']"></router-link>
          </div>
          <currencies v-if="this.$store.state.authenticated"></currencies>
        </div>
      </header>
      <div class="menu-content">
        <div class="container mx-auto max-w-sm mt-8">
          <nav class="flex flex-col">
            <router-link :to="{name: 'dashboard', query: this.$route.query}" class="self-start">ANASAYFA</router-link>
            <router-link :to="{name: 'guide', query: this.$route.query}" class="self-start">NASIL OYNANIR?</router-link>
            <router-link :to="{name: 'help', query: this.$route.query}" class="self-start">YARDIM</router-link>
            <router-link :to="{name: 'privacy', query: this.$route.query}" class="nav-small nav-small-first self-start">KULLANIM ŞARTLARI</router-link>
            <router-link :to="{name: 'kvkk', query: this.$route.query}" class="nav-small self-start">GİZLİLİK POLİTİKASI VE KVKK</router-link>
          </nav>
        </div>
        <div class="container mx-auto max-w-sm">
          <navigation-bottom class="mt-16"></navigation-bottom>
        </div>
      </div>
    </div>
    <div class="main mx-auto">
      <router-view />
    </div>
  </div>
</template>

<script>
import Currencies from "@/views/components/Currencies"
import NavigationBottom from "@/views/components/NavigationBottom"

export default {
  components: {
    Currencies,
    NavigationBottom,
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll)
  },
  watch: {
    $route() {
      this.closeMenu();
    }
  },
  beforeDestroy(){
    document.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onClickMenu() {
      var element = document.querySelector(".nav-wrapper");
      element.classList.toggle("active");
      document.body.classList.toggle("overflow-hidden");
    },
    closeMenu() {
      var element = document.querySelector(".nav-wrapper");
      element.classList.remove("active");
      document.body.classList.remove("overflow-hidden");
    },
    onScroll() {
      if(window.pageYOffset > 0){
        document.querySelector(".nav-wrapper").classList.add("scrolled")
      }
      else {
        document.querySelector(".nav-wrapper").classList.remove("scrolled")
      }
    }
  }
}
</script>

<style>

</style>