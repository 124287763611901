<template>
  <div class="leaderboard">
    <div class="winner-stand flex flex-col items-center mt-4">
      <div class="flex justify-center -mb-14 mt-20">
        <div id="stickman-2" class="-mt-2 flex flex-col relative flex flex-col items-center">
          <div class="absolute flex flex-col items-center mb-2 -top-14">
            <span class="overflow-ellipsis overflow-hidden w-24 text-center">{{topThree[1].nickname}}</span>
            <span class="font-semibold text-xl">{{topThree[1].score}}</span>
          </div>
          <svg width="84" height="146" viewBox="0 0 84 146" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern id="img2" patternUnits="userSpaceOnUse" width="39" height="42">
                <image :href="topThree[1].avatarUrl" x="-4" y="8" width="41" height="41" />
              </pattern>
            </defs>
            <path fill="url(#img2)" d="M67.3105 7.7617C62.783 5.95246 58.7698 3.60038 54.0002 2.71238C49.7799 1.92665 43.6258 4.1069 40.9312 7.47922C37.7918 11.4083 36.2761 16.901 36.2761 21.8857C36.2761 26.2569 36.1963 32.9221 39.3795 36.3627C47.8862 45.5574 64.6612 49.6738 72.5864 37.9517C75.8601 33.1096 75.5375 26.1349 75.3795 20.4733C75.3169 18.2289 74.4535 14.8259 73.3795 12.8463C71.6829 9.71911 68.0036 9.08842 65.4485 7.12612" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M53.0342 46.532C53.0342 62.5016 54.8962 78.3729 54.8962 94.2357C54.8962 101.642 56.1376 108.887 56.1376 116.446" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M56.138 116.446C54.5134 119.069 52.7968 122.007 50.8277 124.39C48.4789 127.233 46.3791 130.764 43.7242 133.288C41.1365 135.749 37.2003 139.34 35.6553 142.504" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M57.3796 118.988C61.1652 124.105 64.8457 129.476 68.0693 134.877C69.0712 136.556 70.6609 137.827 71.6555 139.609C72.2423 140.661 74.7589 143.37 74.7589 141.869" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M51.8586 57.0926C50.4025 53.8443 47.6403 51.3073 45.008 48.999C41.2514 45.7047 37.285 42.7244 33.3234 39.6899C29.8022 36.9928 27.1506 33.7103 24.1249 30.4914C20.9851 27.1512 17.1957 24.4185 13.9457 21.1686C10.4131 17.636 7.11773 14.092 3.62842 10.6027" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M54 56C59.4231 63.8873 62.0858 72.5925 68.0816 80.3583C70.8308 83.9189 74.0286 87.152 76.3163 90.9128C77.6421 93.0923 79.0605 96.1578 81 98" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div id="stickman-1" class="-mt-7 mx-2 relative flex flex-col items-center">
          <div class="absolute flex flex-col items-center -top-14">
            <span class="overflow-ellipsis overflow-hidden w-24 text-center">{{topThree[0].nickname}}</span>
            <span class="font-semibold text-xl">{{topThree[0].score}}</span>
          </div>
          <svg width="108" height="146" viewBox="0 0 108 146" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern id="img1" patternUnits="userSpaceOnUse" width="39" height="42">
                <image :href="topThree[0].avatarUrl" x="-4" y="8" width="41" height="41" />
              </pattern>
            </defs>
            <path fill="url(#img1)" d="M67.3105 7.7617C62.783 5.95246 58.7698 3.60038 54.0002 2.71238C49.7799 1.92665 43.6258 4.1069 40.9312 7.47922C37.7918 11.4083 36.2761 16.901 36.2761 21.8857C36.2761 26.2569 36.1963 32.9221 39.3795 36.3627C47.8862 45.5574 64.6612 49.6738 72.5864 37.9517C75.8601 33.1096 75.5375 26.1349 75.3795 20.4733C75.3169 18.2289 74.4535 14.8259 73.3795 12.8463C71.6829 9.71911 68.0036 9.08842 65.4485 7.12612" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M53.0342 46.532C53.0342 62.5016 54.8962 78.3729 54.8962 94.2357C54.8962 101.642 56.1376 108.887 56.1376 116.446" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M56.138 116.446C54.5134 119.069 52.7968 122.007 50.8277 124.39C48.4789 127.233 46.3791 130.764 43.7242 133.288C41.1365 135.749 37.2003 139.34 35.6553 142.504" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M57.3796 118.988C61.1652 124.105 64.8457 129.476 68.0693 134.877C69.0712 136.556 70.6609 137.827 71.6555 139.609C72.2423 140.661 74.7589 143.37 74.7589 141.869" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M53.8474 58.5842C61.3767 54.669 68.1382 49.5504 74.6063 44.0406C80.8717 38.7034 87.5559 33.5771 93.3763 27.7567C95.9063 25.2267 98.3494 22.1406 101.277 20.0498C102.447 19.2139 103.788 18.5879 104.812 17.5637" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M51.8586 57.0926C50.4025 53.8443 47.6403 51.3073 45.008 48.999C41.2514 45.7047 37.285 42.7244 33.3234 39.6899C29.8022 36.9928 27.1506 33.7103 24.1249 30.4914C20.9851 27.1512 17.1957 24.4185 13.9457 21.1686C10.4131 17.636 7.11773 14.092 3.62842 10.6027" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div id="stickman-3" class="mt-3 relative flex flex-col items-center">
          <div class="absolute flex flex-col items-center -top-14">
            <span class="overflow-ellipsis overflow-hidden w-24 text-center">{{topThree[2].nickname}}</span>
            <span class="font-semibold text-xl">{{topThree[2].score}}</span>
          </div>
          <svg width="77" height="146" viewBox="0 0 77 146" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern id="img3" patternUnits="userSpaceOnUse" width="39" height="42">
                <image :href="topThree[2].avatarUrl" x="4" y="8" width="41" height="41" />
              </pattern>
            </defs>
            <path fill="url(#img3)" d="M36.3105 7.7617C31.783 5.95246 27.7698 3.60038 23.0002 2.71238C18.7799 1.92665 12.6258 4.1069 9.93123 7.47922C6.79183 11.4083 5.27606 16.901 5.27606 21.8857C5.27606 26.2569 5.19628 32.9221 8.37951 36.3627C16.8862 45.5574 33.6612 49.6738 41.5864 37.9517C44.8601 33.1096 44.5375 26.1349 44.3795 20.4733C44.3169 18.2289 43.4535 14.8259 42.3795 12.8463C40.6829 9.71911 37.0036 9.08842 34.4485 7.12612" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.0342 46.532C22.0342 62.5016 23.8962 78.3729 23.8962 94.2357C23.8962 101.642 25.1376 108.887 25.1376 116.446" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.138 116.446C23.5134 119.069 21.7968 122.007 19.8277 124.39C17.4789 127.233 15.3791 130.764 12.7242 133.288C10.1365 135.749 6.20029 139.34 4.65527 142.504" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M26.3796 118.988C30.1652 124.105 33.8457 129.476 37.0693 134.877C38.0712 136.556 39.6609 137.827 40.6555 139.609C41.2423 140.661 43.7589 143.37 43.7589 141.869" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.8474 58.5842C30.3767 54.669 37.1382 49.5504 43.6063 44.0406C49.8717 38.7034 56.5559 33.5771 62.3763 27.7567C64.9063 25.2267 67.3494 22.1406 70.2765 20.0498C71.4468 19.2139 72.7881 18.5879 73.8123 17.5637" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.0601 58.4756C16.533 61.5974 13.942 64.5414 10.8308 67.0996C8.94108 68.6533 7.49629 70.669 5.61698 72.1724C2.7735 74.4472 3.49765 75.3797 6.40609 78.2881C11.1482 83.0302 16.0389 89.1935 19.4547 95.0005C20.2042 96.2747 22.8094 98.5515 24.133 98.5515" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <img :src="require('@/assets/winner-stand.svg')" alt="">
      <div class="ribbons">
        <div class="ribbons__item ribbons__item--silver">
          <span><b>10bin</b> Paycell Puan</span>
        </div>
        <div class="ribbons__item ribbons__item--gold">
          <span><b>15bin</b> Paycell Puan</span>
        </div>
        <div class="ribbons__item ribbons__item--bronze">
          <span><b>5bin</b> Paycell Puan</span>
        </div>
      </div>
    </div>

    <div
        v-infinite-scroll="fetchLeaderboard"
        infinite-scroll-disabled="topTenLoaded"
        :infinite-scroll-immediate-check="false"
        infinite-scroll-distance="100">
    <div class="container mx-auto max-w-sm">
      <div class="leaderboard px-4 mb-8">

        <div class="leaderboard__item" :class="{'active': leaderboardPlayer.player.id == player.id}" v-for="leaderboardPlayer in remainingPlayers" :key="`${leaderboardPlayer.id}-${leaderboardPlayer.currentRank}`">
          <div class="leaderboard__item-number">{{leaderboardPlayer.currentRank}}.</div>
          <div class="leaderboard__item-info">
            <div class="leaderboard__item-avatar">
              <img :src="leaderboardPlayer.player.avatarUrl" alt="">
            </div>
            <div class="leaderboard__item-name">{{leaderboardPlayer.player.alias}}</div>
          </div>
          <div class="leaderboard__item-points">{{leaderboardPlayer.score}}</div>
        </div>

      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      topTenLoaded: true,
      stickyPlayer: false,
      isScrolling: false,
      pagination: {
        offset: 0,
        limit: 10
      },
      refreshInterval: null
    }
  },
  computed: {
    ...mapState(["leaderboard", "player", "leaderboardPagination", "appSettings"]),
    topThree() {
      var leaderboardPlayers = this.leaderboard.slice(0, 3)
      var players = [{nickname: '-', score: '-', avatarUrl: ''}, {nickname: '-', score: '-', avatarUrl: ''}, {nickname: '-', score: '-', avatarUrl: ''}]

      for(var i = 0; i < leaderboardPlayers.length; i++) {
        players[leaderboardPlayers[i].currentRank - 1] = {
          nickname: leaderboardPlayers[i].player.alias,
          score: leaderboardPlayers[i].score,
          avatarUrl: leaderboardPlayers[i].player.avatarUrl
        }
      }

      return players
    },
    remainingPlayers() {
      return this._.drop(this.leaderboard, 3);
    },
  },
  created() {
    this.getTopTen().then(() => {
      this.topTenLoaded = false;
      this.loading = false;
    });
    this.refreshInterval = setInterval(this.getLeaderboard, 60000);
  },
  mounted() {
    window.addEventListener("scroll", this.stickyPlayerEvent)
    window.addEventListener("scroll", this.checkScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.stickyPlayerEvent)
    window.removeEventListener("scroll", this.checkScroll)
    this.emptyLeaderboard();
    clearInterval(this.refreshInterval);
    window.clearTimeout(this.refreshInterval)
  },
  methods: {
    ...mapActions(["getLeaderboard", "refreshLeaderboard", "getTopTen", "emptyLeaderboard"]),
    stickyPlayerEvent() {
      let player = this.leaderboard.find((leaderboardPlayer) => leaderboardPlayer.player.id == this.player.id);
      if(player && player.currentRank > 10) {
        const el = document.getElementsByClassName("leaderboard__item active")[0];
        const elSecond = document.getElementsByClassName("leaderboard__item active")[1];
        const leaderboardEl = document.getElementsByClassName("leaderboard__item")[0];
        const rect = el.getBoundingClientRect();
        if (rect.bottom <= window.innerHeight) {
          el.classList.add("sticky");
          el.style.width = leaderboardEl.offsetWidth + "px";
          const r = document.getElementsByClassName("leaderboard")[0].getBoundingClientRect();
          const bottom = window.innerHeight - r.bottom;
          if(bottom > 0) {
            el.style.bottom = bottom + "px";
            this.stickyPlayer = false;
          }
          else {
            el.style.bottom = 0;
            this.stickyPlayer = true;
          }
        }
        if (elSecond && elSecond.getBoundingClientRect().bottom <= window.innerHeight) {
          el.style.display = "none";
          this.stickyPlayer = false;
        }
        else {
          el.style.display = "flex";
        }
      }
    },
    checkScroll() {
      // Clear our timeout throughout the scroll
      window.clearTimeout( this.isScrolling );
      clearInterval( this.refreshInterval );
      // Set a timeout to run after scrolling ends
      this.isScrolling = setTimeout(() => {
        this.refreshInterval = setInterval(this.onRefreshLeaderboard, 60000);
      }, 400);
    },
    onRefreshLeaderboard() {
      this.loading = true;
      const playerRank = this.player.playerStanding.currentRank > 10 ? 11 : 10;
      this.emptyLeaderboard();
      this.refreshLeaderboard({ params: {limit: this.leaderboard.length - playerRank, offset: 10}}).then(() => {
        this.loading = false;
        this.pagination.offset = 0
        this.pagination.limit = 10
      }).catch(() => {
            this.loading = false;
          })
    },
    fetchLeaderboard() {
      if(!this.loading) {
        this.pagination.offset += this.pagination.limit;
        if(this.leaderboardPagination && this.leaderboardPagination.totalItems > this.leaderboard.length) {
          const remainingCount = this.leaderboardPagination.totalItems - this.leaderboard.length;
          if(remainingCount < this.pagination.limit) {
            this.pagination.limit = remainingCount;
          }
        }
        else if (this.leaderboardPagination && this.leaderboardPagination.totalItems <= this.leaderboard.length ) {
          return;
        }
        else if (!this.leaderboardPagination && this.leaderboard.length < 10) {
          this.pagination.offset = this.leaderboard.length;
        }

        this.loading = true;
        this.getLeaderboard({ params: {limit: this.leaderboard.length <= 10 ? this.pagination.limit + 1 : this.pagination.limit, offset: this.pagination.offset}}).then(() => {
          this.loading = false;
        }).catch(() => {
              this.loading = false;
            })
      }

    }

  }
}
</script>

<style>

</style>