<template>
  <modal
      name="oyna-kazan-modal"
      classes="modal"
      :minWidth="200"
      :minHeight="400"
      :maxWidth="480"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="true"
      :reset="true"
      @before-open="show"
      width="90%"
      height="auto">
    <div class="flex justify-end relative" v-if="!this.$store.state.isLoading">
      <button @click="this.hide" class="modal-close absolute right-0"></button>
    </div>
    <div class="flex flex-col items-center h-full">
      <img src="@/assets/icon-oyna-kazan.svg" style="max-height:80px" alt="">
      <div class="modal-container pb-6">
        <div class="text-base text-center mb-4 mx-4"> Kelimeyi bulursan anında <b class="text-lg">1.000</b>, bulamazsan <b class="text-lg">500 PAYCELL PUAN</b> hediye! </div>
        <div class="btn-oyna-kazan btn-oyna-kazan--secondary mb-8 w-full" @click.prevent="onClickOynaKazanPay('oynakazan.X.0000',29)">
          OYNA KAZAN
          <div class="text-stroke mb-3 font-normal text-4xl"> x1 </div>
          <!--div class="text-stroke text-stroke--green mb-2 text-xl">%22 İNDİRİM</div-->
          <div class="btn-oyna-kazan__value">
            <div class="btn-oyna-kazan__value-number flex items-center" style="font-size:1.60rem">
              <!--div class="flex items-center mr-3 text-xl" style="text-decoration: line-through">
                  9 
                  <span class="text-base">TL</span>
              </div-->
              <div class="flex items-center font-bold text-primary	text-3xl">
                  8
                <span class="text-lg">
                  TL
                </span>
              </div>
            </div>
          </div>
        </div>
        
        <div class="overflow-hidden w-full flex flex-row gap-2">
            <div class="btn-oyna-kazan btn-oyna-kazan--primary mb-8" @click.prevent="onClickOynaKazanPay('oynakazan.X.0005',30)">
              OYNA KAZAN
              <div class="text-stroke mb-2 font-normal text-4xl"> x5 </div>
              <div class="text-stroke text-stroke--green mb-1 text-md">%13 İNDİRİM</div>
              <div class="btn-oyna-kazan__value">
                <div class="btn-oyna-kazan__value-number flex items-center" style="font-size:1.2rem">
                  <div class="flex items-center mr-3 text-md" style="text-decoration: line-through">
                      40
                      <span class="text-base">TL</span>
                  </div>
                  <div class="flex items-center font-bold text-primary	text-xl">
                      35
                    <span class="text-md">
                      TL
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-oyna-kazan btn-oyna-kazan--primary mb-8" @click.prevent="onClickOynaKazanPay('oynakazan.X.0010',31)">
              OYNA KAZAN
              <div class="text-stroke mb-2 font-normal text-4xl"> x10 </div>
              <div class="text-stroke text-stroke--green mb-1 text-md">%19 İNDİRİM</div>
              <div class="btn-oyna-kazan__value">
                <div class="btn-oyna-kazan__value-number flex items-center" style="font-size:1.2rem">
                  <div class="flex items-center mr-3 text-md" style="text-decoration: line-through">
                      80
                      <span class="text-base">TL</span>
                  </div>
                  <div class="flex items-center font-bold text-primary	text-xl">
                      65
                    <span class="text-md">
                      TL
                    </span>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <span style="font-size: 9px;margin-top: 20px">Oyna kazan paket alımının iadesi bulunmamaktadır.</span>
        <div v-if="waithingOynaKazanCreate > 0" class="btn-oyna-kazan btn-oyna-kazan--gray mx-auto" style="cursor: default; margin-bottom: 20px; margin-top: 20px">
          <h2>Yüklenmeyi Bekleyen : {{waithingOynaKazanCreate}}</h2>
          <div style="font-size: 14px;">1 dk. içerisinde yüklenecektir</div>
        </div>
        <div class="mt-6 pt-10 border-t-4 border-black border-dashed w-full" v-if="games.length > 0">
          <div class="btn-oyna-kazan btn-oyna-kazan--gray mx-auto" @click="onclickPlayOynaKazan">
            <h2>KALAN HAKKIN : {{games.length}}</h2>
            <div class="text-lg"> OYUNA DEVAM ET</div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>

import {mapActions} from "vuex";

export default {
  data() {
    return {
      games: [],
      showMultiplePackage: true,
      waithingOynaKazanCreate: 0
    }
  },
  created() {
    const today = new Date();
    let minutes = today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()
    let seconds = today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds()
    let timeLimit = parseInt(today.getHours() + '' + minutes + '' + seconds)
    if(timeLimit > 150000 && timeLimit < 230000){ this.showMultiplePackage = false }
  },
  methods: {
    ...mapActions(["purchaseStart"]),
    show() {
      this.reCalculate()
      //this.prepareGlobalStanding()
      //this.$modal.show('oyna-kazan-modal')
    },
    reCalculate(){
      this.games = (typeof this.$parent.oynaKazan !== "undefined" && this.$parent.oynaKazan !== null) ? this.$parent.oynaKazan : []
      let totalWaith = 0
      if(typeof this.$parent.pendingOynaKazan !== "undefined" && this.$parent.pendingOynaKazan !== null && this.$parent.pendingOynaKazan.length > 0){
        this.$parent.pendingOynaKazan.forEach((oynaKazan) => {
          totalWaith+=oynaKazan.rewardInfo.game
        })
      }
      this.waithingOynaKazanCreate = totalWaith
    },
    hide() {
      this.$modal.hide('oyna-kazan-modal');
    },
    onClickOynaKazanPay(productSku,productId) {
       this.purchaseStart({
         transactionId: this.$route.query.transactionId,
         qty: 1,
         productId: productId
       }).then((response) => {
         // console.log('webkit: ' + window.webkit);
         // console.log('appInterface: ' + window.appInterface);
         // window.webkit.messageHandlers.payment.postMessage({"transactionId":this.$route.query.transactionId,"productId":product.sku})
         // window.appInterface.payment.postMessage({transactionId:this.$route.query.transactionId,productId:product.sku})
         if (window.webkit != undefined) {
           try {
             window.webkit.messageHandlers.receiveMessageFromJS.postMessage(
                 '{"type":"payment", "merchantId":"601", "transactionId":"' + this.$route.query.transactionId + '","productId":"' + productSku + '"}')
             console.log("webkit Var");
           } catch (e) {
             this.$parent.$refs.resultModal.message = response.message;
             this.$parent.$refs.resultModal.success = false
             this.$parent.$refs.resultModal.show()
             this.hide()
           }
         } else if (window.appInterface != undefined) {
           try {
             window.appInterface.postMessage('{"type":"payment", "merchantId":"601", "transactionId":"' + this.$route.query
                 .transactionId + '","productId":"' + productSku + '"}')
             console.log("appInterface Var");

           } catch (e) {
             this.$parent.$refs.resultModal.message = response.message;
             this.$parent.$refs.resultModal.success = false
             this.$parent.$refs.resultModal.show()
             this.hide()
           }
         } else {
           this.$parent.$refs.resultModal.message = "Üzgünüz, bir sorun oluştu. Lütfen daha sonra tekrar deneyin.";
           this.$parent.$refs.resultModal.success = false
           this.$parent.$refs.resultModal.show()
           this.hide()
         }

       }).catch((error) => {
         this.$parent.$refs.resultModal.message = "Üzgünüz, bir sorun oluştu. Lütfen daha sonra tekrar deneyin.";
         this.$parent.$refs.resultModal.success = false
         this.$parent.$refs.resultModal.show()
         this.hide(error)
       })

    },
    onclickPlayOynaKazan(){
      if(typeof(this.games) != "undefined"){
        if(this.games[0].status == 'o' || this.games[0].status == 's'){
          this.$parent.gotoOynaKazan(this.games[0])
        }
      }
    }
  }
}
</script>

<style>

</style>